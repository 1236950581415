import React, { useState, useEffect } from "react";
import {
  getWorkerImg,
  getWorkerVideo,
} from "services/firebase/firebase.service";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
  ApplicationUserSkillDto,
  ApplicationWorkerEndorsementDto,
} from "model/Application";
import { CompanyFavoriteDto } from "../../../../model/Favorite";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled, TextArea } from "components/form/inputs/inputs.styled";
import {
  VideoPresentation,
  DividerLine,
  AboutDiv,
  GreenCell,
  Cell,
  SkillsCell,
  RowWrapper,
  ProfileDetailsContainer,
  ApplicantGrid,
  ProfileDiv,
  CompanyImage,
  ModalBody,
  ButtonContainer,
  ImageContainer,
  BackCustomLink,
  VideoContainer,
  VideoWrapper,
  Column,
  SkillCell,
  SkillsContainer,
  StatsContainer,
} from "./applicantDetails.styled";
import { formatWorkerDateOfBirth } from "web-apps/company/utils/utils";
import { WorkerEndorementModal } from "./workerEndorsementModal";
import { UserSkillDto } from "model/User";
import { WorkerDocuments } from "../workerDocument/workerDocument.component";
import { useHistory } from "react-router-dom";

type Props = {
  applicant: ApplicationDto;
};

enum Response {
  REJECT,
  ACCEPT,
}

export const ApplicantDetails: React.FC<Props> = ({ applicant }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [, applicationActions] = useApplicationStore();
  const [companyState, companyActions] = useCompanyStore();
  const [workerProfileImage, setWorkerProfileImage] = useState("");
  const [workerVideo, setWorkerVideo] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [favoriteModalOpen, setFavoriteModalOpen] = useState(false);
  const [favorited, setFavorited] = useState<CompanyFavoriteDto | null>(null);
  const [respondApplication, setRespondApplication] = useState<Response>();
  const [declineReason, setDeclineReason] = useState("");
  const [createWorkerEndorsementSkill, setCreateWorkerEndorsementSkill] =
    useState<ApplicationUserSkillDto | null>(null);
  const [workerEndorsements, setWorkerEndorsements] = useState<
    ApplicationWorkerEndorsementDto[]
  >([]);
  const [skillAlreadyEndorsed, setSkillAlreadyEndorsed] =
    useState<boolean>(false);
  const [workerEndorsementModalOpen, setWorkerEndorsementModalOpen] =
    useState(false);
  const history = useHistory();
  useEffect(() => {
    const fetchWorkerEndorsements = async (
      companyId: number,
      workerId: number
    ) => {
      const res = await applicationActions.getApplicationWorkerEndorsements(
        companyId,
        workerId
      );
      setWorkerEndorsements(res.data);
    };

    if (companyState.company && applicant.worker.id) {
      fetchWorkerEndorsements(
        companyState.company.id,
        applicant.worker.id
      ).catch(console.error);
    }
  }, [companyState.company, applicant]);

  useEffect(() => {
    const matching = companyState.favorites.find((worker) => {
      return worker.workerId === applicant.worker.id;
    });
    if (matching) {
      setFavorited(matching);
    } else {
      setFavorited(null);
    }
  }, [companyState.favorites]);

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED
  ) => {
    if (companyState.company) {
      const data = {
        ...applicant,
        status,
        ...(status === ApplicationStatus.CLOSED && {
          resolution: ApplicationResolution.REJECTED,
          declineReason,
        }),
      };
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicant.id, data)
        .then(() => {
          if (status === ApplicationStatus.OFFERED) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                title: t("ApplicationsCompany.Offered"),
                message: t("ApplicationsCompany.ApplicantHasBeenOfferedGig"),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                title: t("ApplicationsCompany.Rejected"),
                message: t("ApplicationsCompany.ApplicationHasBeenRejected"),
              },
            });
          }
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
            },
          });
        });
    }
  };

  const handleAcceptApplicant = () => {
    setRespondApplication(Response.ACCEPT);
    setModalOpen(true);
  };

  const handleRejectApplicant = () => {
    setRespondApplication(Response.REJECT);
    setModalOpen(true);
  };

  const removeFromFavorites = () => {
    if (favorited && companyState.company) {
      companyActions
        .removeCompanyFavorite(companyState.company?.id, favorited.workerId)
        .then(() => setFavoriteModalOpen(false))
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("General.ErrorOccurred"),
            },
          });
        });
    }
  };

  const addToFavorites = () => {
    if (companyState.company) {
      companyActions
        .addCompanyFavorite(companyState.company?.id, applicant.worker.id)
        .then()
        .catch((err: any) => {
          if (err.response.data === "Worker does not have a valid SSN") {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("ApplicationsCompany.WorkerDoesNotHaveAValidSSN"),
              },
            });
          }
        });
    }
  };

  const renderFavoriteModalBody = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyFavorite.AreYouSureRemoveFavorite")}
        </Text>
        <ButtonContainer>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setFavoriteModalOpen(false);
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.Destructive}
            onClick={() => removeFromFavorites()}
          >
            {t("CompanyFavorite.Remove")}
          </ButtonStyled>
        </ButtonContainer>
      </>
    );
  };

  const createEndorsement = (skill: string) => {
    const companyEndorsement = workerEndorsements.find(
      (e) => e.companyId === companyState?.company?.id && e.skill === skill
    );

    if (companyEndorsement) {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "alert", // TODO: ADD SAME ICON AS IN APP
          title: t("Alerts.OhNo"),
          message: t("Alerts.SomethingWentWrong"),
        },
      });
    } else {
      if (skill && applicant.worker.id && companyState?.company?.id) {
        applicationActions
          .createApplicationWorkerEndorsement(
            companyState?.company?.id,
            applicant.worker.id,
            skill
          )
          .then((res) => {
            if (res.data?.id) {
              setWorkerEndorsements([...workerEndorsements, res.data]);
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark",
                  title: t("ApplicationsCompany.Endorsed"),
                  message: t(
                    "ApplicationsCompany.CreateEndorsementSuccessfully"
                  ),
                },
              });
            } else {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "alert", // TODO: ADD SAME ICON AS IN APP
                  title: t("Alerts.OhNo"),
                  message: t("Alerts.SomethingWentWrong"),
                },
              });
            }
            setWorkerEndorsementModalOpen(false);
          });
      }
    }
  };
  const deleteEndorsement = (skill: string) => {
    const endorsementToRemove = workerEndorsements.find(
      (e) =>
        e.companyId === companyState?.company?.id &&
        e.skill.toLowerCase() === skill.toLowerCase()
    );

    if (endorsementToRemove) {
      applicationActions
        .deleteApplicationWorkerEndorsement(
          endorsementToRemove.companyId,
          endorsementToRemove.workerId,
          endorsementToRemove.id
        )
        .then((res) => {
          if (res.status === 200) {
            const filteredEndorsements = workerEndorsements.filter(
              (e) => e.id !== endorsementToRemove?.id
            );
            setWorkerEndorsements(filteredEndorsements);

            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("General.Deleted"),
                message: t("ApplicationsCompany.DeleteEndorsementSuccessfully"),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.OhNo"),
                message: t("Alerts.SomethingWentWrong"),
              },
            });
          }
          setWorkerEndorsementModalOpen(false);
        });
    } else {
      setWorkerEndorsementModalOpen(false);
    }
  };
  const renderApplicationModalBody = () => {
    if (respondApplication === Response.ACCEPT) {
      return (
        <>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.OfferGigTo", {
              user: "1",
            })}
          </Text>
          <ButtonContainer>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("GigsCompany.Discard")}
            </ButtonStyled>
            <ButtonStyled
              backgroundColor={Color.BurntSienna}
              onClick={() => updateApplicationStatus(ApplicationStatus.OFFERED)}
            >
              {t("GigsCompany.SendOffer")}
            </ButtonStyled>
          </ButtonContainer>
        </>
      );
    } else if (respondApplication === Response.REJECT) {
      return (
        <>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.YouAreAboutToRejectApplication", {
              user: applicant.worker.firstName,
            })}
          </Text>
          <LabelStyled>{t("GigsCompany.NoteToApplicant")}</LabelStyled>
          <TextArea
            placeholder={t("GigsCompany.SendANote")}
            rows={5}
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
          <ButtonContainer>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("GigsCompany.Discard")}
            </ButtonStyled>
            <ButtonStyled
              backgroundColor={Color.Destructive}
              onClick={() => updateApplicationStatus(ApplicationStatus.CLOSED)}
            >
              {t("GigsCompany.Reject")}
            </ButtonStyled>
          </ButtonContainer>
        </>
      );
    }
  };

  useEffect(() => {
    if (applicant.worker.firebaseId) {
      getWorkerImg(applicant.worker.firebaseId).then((res) => {
        setWorkerProfileImage(res);
      });
      getWorkerVideo(applicant.worker.firebaseId)
        .then((res) => setWorkerVideo(res))
        .catch(() => {
          setWorkerVideo(null);
        });
    }
  }, [applicant]);

  return (
    <>
      <BackCustomLink
        color={Color.Destructive}
        onClick={() => history.goBack()}
      >
        {t("General.Back")}
      </BackCustomLink>

      <ApplicantGrid>
        <ProfileDiv>
          <ImageContainer>
            {workerProfileImage ? (
              <CompanyImage src={workerProfileImage} />
            ) : (
              <CustomIcon
                key={1}
                className="icon"
                square
                width="250px"
                name="person"
                size="190px"
                color={Color.SeaBlue500}
                backgroundColor={Color.SeaBlue300}
                padding="10px"
              />
            )}

            {favorited ? (
              <CustomIcon
                key={2}
                className="icon"
                name="favorites-active"
                size="48px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={() => setFavoriteModalOpen(true)}
              />
            ) : (
              <CustomIcon
                key={3}
                className="icon"
                name="favorites-inactive"
                size="48px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={addToFavorites}
              />
            )}
          </ImageContainer>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {applicant.worker.firstName} {applicant.worker.lastName}
          </Text>
          {applicant.worker.dateOfBirth && (
            <Text
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {formatWorkerDateOfBirth(applicant.worker.dateOfBirth)}
            </Text>
          )}
          <StatsContainer>
            {applicant.worker.stats &&
              applicant.worker.stats.some(
                (stat: any) => stat.stat === "AmountOfHoursWorked"
              ) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomIcon
                      name="clock"
                      color={Color.BurntSienna}
                      size="24px"
                      style={{ marginRight: "4px" }}
                    />
                    <Text
                      color={Color.MidnightBlue}
                      fontFamily={FontFamily.MontserratBold}
                      fontSize={FontSize.Large}
                    >
                      {applicant.worker.stats &&
                      applicant.worker.stats.some(
                        (stat: any) => stat.stat === "AmountOfHoursWorked"
                      )
                        ? applicant.worker.stats
                            .find(
                              (stat: any) => stat.stat === "AmountOfHoursWorked"
                            )
                            .value.toFixed(0)
                        : 0}
                    </Text>
                  </div>
                  <Text color={Color.SeaBlue500}>
                    {t("ProfileWorker.HoursWorked")}
                  </Text>
                </div>
              )}
            {applicant.worker.stats &&
            applicant.worker.stats.some(
              (stat: any) => stat.stat === "Rating"
            ) ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomIcon
                    name="star"
                    color={Color.BurntSienna}
                    size="20px"
                    style={{ marginRight: "4px" }}
                  />
                  <Text
                    color={Color.MidnightBlue}
                    fontFamily={FontFamily.MontserratBold}
                    fontSize={FontSize.Large}
                  >
                    {applicant.worker.stats &&
                    applicant.worker.stats.some(
                      (stat: any) => stat.stat === "Rating"
                    )
                      ? applicant.worker.stats
                          .find((stat: any) => stat.stat === "Rating")
                          .value.toFixed(1)
                      : 0}
                    /5
                  </Text>
                  <Text
                    color={Color.SeaBlue500}
                    fontFamily={FontFamily.MontserratRegular}
                    fontSize={FontSize.Standard}
                    style={{ marginLeft: "4px" }}
                  >
                    {applicant.worker.stats &&
                    applicant.worker.stats.some(
                      (stat: any) => stat.stat === "AmountOfRatings"
                    )
                      ? "(" +
                        applicant.worker.stats
                          .find((stat: any) => stat.stat === "AmountOfRatings")
                          .value.toFixed(0) +
                        ")"
                      : "(" + 0 + ")"}
                  </Text>
                </div>
                <Text color={Color.SeaBlue500}>
                  {t("ProfileWorker.Rating")}
                </Text>
              </div>
            ) : (
              <Text color={Color.SeaBlue500}>
                {t("ProfileWorker.NoRatingsYet")}
              </Text>
            )}
          </StatsContainer>

          <ProfileDetailsContainer>
            <LabelStyled>{t("ProfileWorker.Contact")}</LabelStyled>
            <Text
              style={{ wordBreak: "break-all" }}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              <a
                href={
                  applicant.worker.email && "mailto:" + applicant.worker.email
                }
                style={{ color: Color.BurntSienna }}
              >
                {applicant.worker.email || ""}
              </a>
            </Text>

            <Text
              style={{ wordBreak: "break-all" }}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              <a
                style={{ color: Color.BurntSienna }}
                href={`tel:+${applicant.worker.phoneNumber}`}
              >
                {`+${applicant.worker.phoneNumber}`}
              </a>
            </Text>
          </ProfileDetailsContainer>
          <ProfileDetailsContainer>
            <div style={{ height: 300 }}>
              <LabelStyled>{t("ProfileWorker.Bio")}</LabelStyled>
              <Text style={{ wordBreak: "break-word" }}>
                {applicant.worker.description}
              </Text>
            </div>
          </ProfileDetailsContainer>

          <VideoContainer>
            <LabelStyled>{t("ProfileWorker.VideoPresentation")}</LabelStyled>

            {workerVideo ? (
              <VideoWrapper>
                <VideoPresentation controls src={workerVideo} />
              </VideoWrapper>
            ) : (
              <Text color={Color.SeaBlue400}>
                {t("ProfileWorker.NoVideoPresentation")}
              </Text>
            )}
          </VideoContainer>
          <SkillsContainer>
            <LabelStyled>{t("ProfileWorker.Skills")}</LabelStyled>
            <SkillsCell>
              {applicant.worker.skills.map((skill, index) => (
                <SkillCell
                  onClick={() => {
                    workerEndorsements.find(
                      (endorsement) =>
                        endorsement.companyId === companyState.company?.id &&
                        endorsement.skill.toLowerCase() ===
                          skill.skill.toLowerCase()
                    )
                      ? setSkillAlreadyEndorsed(true)
                      : setSkillAlreadyEndorsed(false);
                    setWorkerEndorsementModalOpen(true);
                    setCreateWorkerEndorsementSkill(skill);
                  }}
                >
                  <Column>
                    <Text
                      fontSize={FontSize.Large}
                      color={Color.MidnightBlue}
                      fontFamily={FontFamily.MontserratSemiBold}
                      style={{ marginBottom: "4px" }}
                    >
                      {t(skill.skill, { ns: "skills" })}
                    </Text>
                    <Text fontSize={FontSize.Small} color={Color.SeaBlue500}>
                      {skill.experience === 0
                        ? `3-9 ${t("ProfileWorker.Months")}`
                        : skill.experience < 2
                        ? `1-2 ${t("ProfileWorker.Years")}`
                        : skill.experience < 5
                        ? `2-5 ${t("ProfileWorker.Years")}`
                        : `+5 ${t("ProfileWorker.Years")}`}
                    </Text>
                  </Column>
                  <Text
                    fontSize={FontSize.Small}
                    color={Color.SeaBlue500}
                    fontFamily={FontFamily.MontserratRegular}
                  >
                    {
                      workerEndorsements.filter(
                        (endorsement) =>
                          endorsement.skill.toLowerCase() ===
                          skill.skill.toLowerCase()
                      ).length
                    }{" "}
                    {t("ProfileWorker.Endorsements")}
                  </Text>
                </SkillCell>
              ))}
            </SkillsCell>
          </SkillsContainer>
          <WorkerDocuments documents={applicant.worker.documents} />
        </ProfileDiv>
        <DividerLine />
        <AboutDiv>
          <GreenCell>
            <LabelStyled color={Color.MidnightBlue}>
              {t("ProfileWorker.RequestedRate")}
            </LabelStyled>
            <Text>{`${applicant.requestedRate} ${t("General.Currency")}`}</Text>
          </GreenCell>
          <Cell>
            <LabelStyled>{t("ProfileWorker.MotivationalLetter")}</LabelStyled>
            <Text>{applicant.motivationalLetter}</Text>
          </Cell>
          {applicant.declineReason ? (
            <Cell style={{ marginTop: 20 }}>
              <LabelStyled>{t("GigsCompany.NoteToApplicant")}</LabelStyled>
              <Text>{applicant.declineReason}</Text>
            </Cell>
          ) : null}
          {applicant.status === ApplicationStatus.PENDING && (
            <RowWrapper>
              <ButtonStyled
                backgroundColor={Color.Destructive}
                onClick={handleRejectApplicant}
              >
                {t("GigsCompany.Reject")}
              </ButtonStyled>
              <ButtonStyled
                backgroundColor={Color.BurntSienna}
                onClick={handleAcceptApplicant}
              >
                {t("GigsCompany.SendOffer")}
              </ButtonStyled>
            </RowWrapper>
          )}
          {applicant.resolution === ApplicationResolution.ACCEPTED && (
            <RowWrapper>
              <Text
                backgroundColor={Color.SeaBlue400}
                padding
                color={Color.White}
              >
                {t("ApplicationsCompany.Hired")}
              </Text>
            </RowWrapper>
          )}
          {applicant.status === ApplicationStatus.OFFERED && (
            <RowWrapper>
              <Text
                backgroundColor={Color.SeaBlue400}
                padding
                color={Color.White}
              >
                {t("Alerts.Offered")}
              </Text>
            </RowWrapper>
          )}
          {applicant.resolution === ApplicationResolution.REJECTED && (
            <RowWrapper>
              <Text
                backgroundColor={Color.Destructive}
                padding
                color={Color.White}
              >
                {t("Alerts.Rejected")}
              </Text>
            </RowWrapper>
          )}
        </AboutDiv>
      </ApplicantGrid>
      {createWorkerEndorsementSkill && (
        <WorkerEndorementModal
          workerEndorsementModalOpen={workerEndorsementModalOpen}
          createWorkerEndorsementSkill={createWorkerEndorsementSkill}
          deleteEndorsement={deleteEndorsement}
          createEndorsement={createEndorsement}
          setWorkerEndorsementModalOpen={setWorkerEndorsementModalOpen}
          skillAlreadyEndorsed={skillAlreadyEndorsed}
          workerEndorsements={workerEndorsements}
        />
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>{renderApplicationModalBody()}</ModalBody>
      </Modal>
      <Modal
        open={favoriteModalOpen}
        onClose={() => {
          setFavoriteModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>{renderFavoriteModalBody()}</ModalBody>
      </Modal>
    </>
  );
};
