import { AxiosResponse } from "axios";
import { API_ROOT } from "services/baseURLs.config";
import { axiosRequest } from "../api.utils";

const getStaffingPoolWorkers = async (
  companyId: number,
  staffingPoolId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/staffingpools/${staffingPoolId}/workers`
  );
};

const getStaffingPools = async (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workgroups?type=Staffing&active=true&amount=50&page=0`
  );
};

export const staffingWorkerPool = {
  getStaffingPoolWorkers,
  getStaffingPools,
};
