import {
  StyledButton,
  IconHolder,
  StyledIcon,
  IconContainer,
  Divider,
  TextContainer,
} from "./DropdownItem.styled";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ListViewModel } from "./Dropdown";

interface DropdownItemProps {
  model: ListViewModel;
  isSelected: boolean;
  onClickItem?: (vm: ListViewModel) => void;
  onRemoveItem?: (vm: ListViewModel) => void;
  onClickDetails?: (vm: ListViewModel) => void;
  smallerFont?: boolean;
  disabled?: boolean;
}

export const DropdownItem = ({
  model,
  isSelected,
  onClickItem,
  onClickDetails,
  onRemoveItem,
  smallerFont,
  disabled,
}: DropdownItemProps) => {
  const onClick = () => {
    onClickItem?.(model);
  };

  const onRemoveClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onRemoveItem?.(model);
  };

  const onDetailsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClickDetails?.(model);
  };

  return (
    <StyledButton type={"button"} onClick={!disabled ? onClick : () => {}}>
      <TextContainer style={{ maxWidth: 250 }}>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={smallerFont ? FontSize.Standard : FontSize.Large}
          color={disabled ? Color.SeaBlue300 : Color.MidnightBlue}
          style={{
            marginTop: 20,
            marginBottom: 0,
            maxWidth: 250,
            whiteSpace: "normal", 
            wordWrap: "normal", 
            overflowWrap: "normal", 
            wordBreak: "keep-all", 
          }}
        >
          {model.label}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratRegular}
          fontSize={FontSize.Tiny}
          color={Color.MidnightBlue}
          style={{
            maxWidth: 170,
            whiteSpace: "normal", 
            wordWrap: "normal", 
            overflowWrap: "normal", 
            wordBreak: "keep-all", 
          }}
        >
          {model.description}
        </Text>
      </TextContainer>
      <Divider />
      <IconHolder>
        {isSelected && (
          <StyledIcon name="checkmark" size="28px" color={Color.BurntSienna} />
        )}
        {onClickDetails && (
          <StyledIcon
            name="pen"
            size="28px"
            backgroundColor={Color.BurntSienna}
            color={Color.White}
            padding="4px"
            onClick={onDetailsClick}
          />
        )}
        {onRemoveItem && (
          <IconContainer>
            <StyledIcon
              name="trashcan"
              size="28px"
              color={Color.White}
              padding="4px"
              onClick={onRemoveClick}
            />
          </IconContainer>
        )}
      </IconHolder>
    </StyledButton>
  );
};
