import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
import { Text } from "components/Typography/text.styled";
import {
  CompanyCalendarGigDto,
  CompanyCalendarShift,
  CompanyCalendarTimereport,
  Filter,
  OfferList,
} from "model/Calendar";
import { ShiftCell } from "../weeklyCalendar.styled";
import Modal from "@material-ui/core/Modal";
import { OfferModal } from "../../gigDetail/offerModal.component";
import { GigType } from "model/Gig";

type Props = {
  name: string;
  compactView: boolean;
  shift: CompanyCalendarShift;
  timeReport: CompanyCalendarTimereport | undefined;
  keyId: number;
  dashboard: boolean | undefined;
  gigData: CompanyCalendarGigDto;
  filter: Filter[];
};

export const WithdrawnCalendarGig: React.FC<Props> = ({
  name,
  compactView,
  shift,
  timeReport,
  keyId,
  dashboard,
  gigData,
  filter,
}) => {
  const { t } = useTranslation();
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerList, setOfferList] = useState<OfferList[]>([]);
  const [offerHourlyRate, setOfferHourlyRate] = useState(0);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);

  const tooltipContent = compactView
    ? `${moment(timeReport?.startTime).format("HH:mm") ?? ""} - ${
        moment(timeReport?.endTime).format("HH:mm") ?? ""
      }`
    : name;

  return (
    <>
      <ShiftCell
        key={keyId}
        backgroundColor={Color.LightWarning}
        data-tooltip-id={"worker-name"}
        data-tooltip-content={tooltipContent}
        onClick={() => {
          if (timeReport && gigData.type !== GigType.PUBLIC) {
            setOfferModalOpen(true);
          }
        }}
      >
        {!compactView ? (
          <Text color={Color.White}>
            {timeReport
              ? `${moment(timeReport.startTime).format("HH:mm")} - ${moment(
                  timeReport.endTime
                ).format("HH:mm")}`
              : ""}
          </Text>
        ) : (
          <Text fontSize={FontSize.Small} color={Color.White}>{`${
            name.length > 6 && dashboard
              ? `${name.slice(0, 6)}...`
              : name
              ? `${name}`
              : dashboard && !name
              ? t(t("CalendarCompany.NotFilled")).slice(0, 7) + "..."
              : t(t("CalendarCompany.NotFilled"))
          }`}</Text>
        )}
        {!compactView ? (
          <Text color={Color.White}>{tooltipContent}</Text>
        ) : (
          <Tooltip
            id={"worker-name"}
            place="top"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
      </ShiftCell>
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={gigData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
          newOffer
          clickedShift={shift.id}
          filter={filter}
          type={gigData?.type}
          selectedWorkGroup={
            gigData?.type == GigType.WORKPOOL ? gigData.workGroupId : null
          }
          amountOfWorkers={gigData?.amountOfWorkers}
        />
      </Modal>
    </>
  );
};
