import React, { useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import {
  DropDownContainer,
  LinkWrapper,
  ModalBody,
  PhoneNumber,
  PhoneNumberContainer,
  PhoneNumberItem,
  PhoneNumbersContainer,
  PhoneNumberTextInput,
} from "./workgroup.styled";
import { IconCircularContainer } from "../dashboard/dashboard.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { WorkGroupRate } from "./workgroupRate.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Dropdown } from "components/dropdown/Dropdown";
import { WorkerClassification, WorkGroup } from "model/Workgroup";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowInviteWorkerModal: React.Dispatch<React.SetStateAction<boolean>>;
  showInviteWorkerModal: boolean;
  workGroups: WorkGroup[];
  fromDetails?: boolean;
};

export const WorkGroupInviteWorkerModal: React.FC<Props> = ({
  setShowInviteWorkerModal,
  showInviteWorkerModal,
  workGroups,
  fromDetails,
}) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("+46");
  const [hourlyRate, setHourlyRate] = useState<string>("0");
  const [monthlyRate, setMonthlyRate] = useState<string>("0");
  const [phoneNumbers, setPhoneNumbers] = useState<String[]>([]);
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<number | null>(
    fromDetails ? workGroups[0].id : null
  );
  const numberRegex = /^([+]46)(7[02369])(\d{7})$/;
  const [companyState, ,] = useCompanyStore();
  const [, workgroupActions] = useWorkgroupStore();
  const [errorText, setErrorText] = useState<string>();
  const [, , alertDispatch] = useAlertStore();
  const filteredWorkGroups = workGroups.filter(
    (workGroup) => workGroup.type !== "Favorite"
  );
  const [selectedWorkerClassification, setSelectedWorkerClassification] =
    useState<string | null>(null);

  const workerClassifications = Object.entries(WorkerClassification)
    .filter(([key, value]) => isNaN(Number(key)))
    .map(([key, value]) => ({ value, name: key }));

  const handleInvite = () => {
    phoneNumbers.map((number, index) => {
      if (
        companyState.company &&
        selectedWorkGroup &&
        phoneNumbers.length !== 0 &&
        hourlyRate &&
        monthlyRate &&
        selectedWorkerClassification
      ) {
        workgroupActions
          .inviteWorkerToWorkGroup(
            companyState.company.id,
            selectedWorkGroup,
            number.substring(1),
            parseInt(hourlyRate),
            parseInt(monthlyRate),
            selectedWorkerClassification
          )
          .then(() => {
            deletePhoneNumber(index);
            if (index == phoneNumbers.length - 1) {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark",
                  title: t("WorkGroup.Sent"),
                  message: t("WorkGroup.InvitationIsSent"),
                },
              });
              setShowInviteWorkerModal(false);
            }
          })
          .catch((err: any) => {
            console.log(err);
            if (err.response.data.errorMessage == "Invite already exists") {
              setErrorText(t("WorkGroup.InviteAlreadyExists"));
            } else if (
              err.response.data.errorMessage == "Phone number not found"
            ) {
              setErrorText(t("WorkGroup.PhonenumberNotFound", { number }));
            }
          });
      }
    });
  };

  const deletePhoneNumber = (indexToDelete: number) => {
    setPhoneNumbers((prevNumbers) =>
      prevNumbers.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <>
      <Modal
        open={showInviteWorkerModal}
        onClose={() => {
          setShowInviteWorkerModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowInviteWorkerModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H3}
          >
            {!fromDetails
              ? t("WorkGroup.Invite")
              : t("WorkGroup.InviteTo", {
                  groupName: workGroups[0].name,
                })}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ marginBottom: 10 }}
          >
            {t("WorkGroup.InviteWorkerByPhone")}
          </Text>
          <PhoneNumberContainer>
            <PhoneNumberTextInput
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={t("WorkGroup.PhoneNumber")}
            />
            <IconCircularContainer
              isAccept
              isDisabled={!numberRegex.test(phoneNumber)}
              onClick={() => {
                if (numberRegex.test(phoneNumber)) {
                  setPhoneNumbers((prevNumbers) => [
                    ...prevNumbers,
                    phoneNumber,
                  ]);
                  setPhoneNumber("+46");
                }
              }}
              style={{ marginLeft: 5 }}
            >
              <CustomIcon
                className="icon"
                name="add"
                size="20px"
                padding="2px"
                color={Color.White}
              />
            </IconCircularContainer>
          </PhoneNumberContainer>
          <PhoneNumbersContainer>
            {phoneNumbers &&
              phoneNumbers.length !== 0 &&
              phoneNumbers.map((number, index) => (
                <PhoneNumberItem key={index}>
                  <PhoneNumber>
                    <Text color={Color.White} fontSize={FontSize.Tiny}>
                      {number}
                    </Text>
                    <CustomIcon
                      className="icon"
                      name="cross"
                      size="20px"
                      padding="2px"
                      color={Color.White}
                      onClick={() => {
                        deletePhoneNumber(index);
                      }}
                      style={{
                        cursor: numberRegex.test(phoneNumber)
                          ? "pointer"
                          : "auto",
                      }}
                    />
                  </PhoneNumber>
                </PhoneNumberItem>
              ))}
          </PhoneNumbersContainer>
          {!fromDetails && (
            <>
              <Text
                style={{ marginTop: 10, marginBottom: 0 }}
                fontSize={FontSize.Small}
              >
                {t("WorkGroup.WorkGroup")}
              </Text>
              <DropDownContainer style={{ marginTop: 10 }}>
                <Dropdown
                  disabled={fromDetails}
                  smallerFont
                  textColor={Color.MidnightBlue}
                  placeholder={t("WorkGroup.WorkGroup")}
                  activeValue={
                    selectedWorkGroup ? selectedWorkGroup : undefined
                  }
                  items={filteredWorkGroups.map((m: any) => ({
                    value: m.id,
                    label: m.name,
                  }))}
                  maxHeight={200}
                  onChange={(e: any) => {
                    setSelectedWorkGroup(e.value);
                  }}
                />
              </DropDownContainer>
            </>
          )}
          <Text
            style={{ marginTop: 10, marginBottom: 0 }}
            fontSize={FontSize.Small}
          >
            {t("WorkGroup.WorkerClassification")}
          </Text>
          <DropDownContainer style={{ marginTop: 10 }}>
            <Dropdown
              smallerFont
              textColor={Color.MidnightBlue}
              placeholder={t("WorkGroup.WorkerClassification")}
              activeValue={
                selectedWorkerClassification
                  ? selectedWorkerClassification
                  : undefined
              }
              items={workerClassifications.map((m: any) => ({
                value: m.name,
                label: t(`WorkGroup.${m.name}`),
                description: t(`WorkGroup.${m.name + 'Description'}`)
              }))}
              maxHeight={200}
              onChange={(e: any) => {
                setSelectedWorkerClassification(e.value);
              }}
            />
          </DropDownContainer>
          <WorkGroupRate
            monthlyRate={monthlyRate}
            setMonthlyRate={setMonthlyRate}
            hourlyRate={hourlyRate}
            setHourlyRate={setHourlyRate}
          />
          {errorText && (
            <Text
              color={Color.LighterDestructive}
              style={{ margin: "0 auto", marginTop: 20 }}
            >
              {errorText}
            </Text>
          )}
          <ButtonStyled
            disabled={phoneNumbers.length == 0}
            style={{ marginTop: 20 }}
            backgroundColor={Color.ModernGreen}
            onClick={() => {
              handleInvite();
            }}
          >
            {t("WorkGroup.Invite")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
