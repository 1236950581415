import {
  CompanyDto,
  CompanyOBSetting,
  CompanyUser,
  StampClockTemplate,
  TimeReportForStamping,
  UserWithTimeReportForStampClock,
  CompanyWorkerDto,
} from "model/Company";
import { CompanyFavoriteDto } from "model/Favorite";

type companyAction =
  | {
      type: "UPDATE_COMPANY_INFORMATION";
      payload: CompanyDto;
    }
  | {
      type: "UPDATE_COMPANIES";
      payload: CompanyDto[];
    }
  | { type: "CLEAR_COMPANY_INFORMATION" }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_IMAGE_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: CompanyState["error"] }
  | {
      type: "GET_COMPANY_IMAGE";
      payload: string;
    }
  | {
      type: "UPDATE_COMPANY_IMAGE";
      payload: string;
    }
  | {
      type: "REMOVE_COMPANY_FAVORITE";
      payload: number;
    }
  | {
      type: "GET_COMPANY_FAVORITES";
      payload: CompanyFavoriteDto[];
    }
  | {
      type: "UPDATE_COMPANY_USER";
      payload: CompanyUser;
    }
  | {
      type: "GET_COMPANY_USERS";
      payload: CompanyUser[];
    }
  | {
      type: "UPDATE_COMPANY_USERS";
      payload: CompanyUser;
    }
  | {
      type: "REMOVE_COMPANY_USER";
      payload: number;
    }
  | {
      type: "ADD_COMPANY_FAVORITE";
      payload: CompanyFavoriteDto;
    }
  | {
      type: "UPDATE_COMPANY_OBSETTINGS";
      payload: CompanyOBSetting[];
    }
  | {
      type: "GET_TIME_REPORT_FOR_STAMPING";
      payload: TimeReportForStamping[];
    }
  | {
      type: "HANDLE_CHECKIN_CHECKOUT_USER";
      payload: UserWithTimeReportForStampClock[];
    }
  | {
      type: "GET_STAMP_CLOCK_TEMPLATE";
      payload: StampClockTemplate;
    }
  | { type: "GET_STAMP_CLOCK_LOCAL_ONLY_SETTING"; payload: boolean }
  | { type: "GET_ALLOW_AUTOGENERATE_SHIFT_SETTING"; payload: boolean }
  | { type: "GET_WORKER_DETAILS"; payload: CompanyWorkerDto[] };

export type CompanyActions = companyAction;

export type CompanyState = {
  companies: CompanyDto[];
  companyUser: CompanyUser | null;
  company: CompanyDto | null;
  companyImage: string | null;
  isLoading: boolean;
  imageIsLoading: boolean;
  favorites: CompanyFavoriteDto[];
  error: { message: string; err: Error } | null;
  users: CompanyUser[];
  obSettings: CompanyOBSetting[];
  timeReportForStamping: TimeReportForStamping[];
  userWithTimeReportForStampClock: UserWithTimeReportForStampClock[];
  template: StampClockTemplate;
  localOnly: boolean;
  allowAutoGenerate: boolean;
  worker: CompanyWorkerDto[];
};

export const companyInitState: CompanyState = {
  companies: [],
  companyUser: null,
  company: null,
  companyImage: null,
  isLoading: false,
  imageIsLoading: false,
  favorites: [],
  error: null,
  users: [],
  obSettings: [],
  timeReportForStamping: [],
  userWithTimeReportForStampClock: [],
  template: {
    companyId: undefined,
    role: "",
    startTime: "",
    endTime: "",
    breakTime: 0,
    hourlyRate: 0,
    paymentType: "",
  },
  localOnly: false,
  allowAutoGenerate: false,
  worker: [],
};

export const companyStateReducer = (
  state: CompanyState,
  action: CompanyActions
): CompanyState => {
  switch (action.type) {
    case "UPDATE_COMPANIES": {
      return {
        ...state,
        companies: action.payload,
      };
    }
    case "UPDATE_COMPANY_INFORMATION": {
      const newState = {
        ...state,
        company: { ...state.company, ...action.payload },
      };
      return newState;
    }
    case "CLEAR_COMPANY_INFORMATION": {
      return {
        ...state,
        ...companyInitState,
      };
    }
    case "GET_COMPANY_IMAGE": {
      const newCompanies = state.companies.map((currentCompany) =>
        state.company?.id === currentCompany.id
          ? { ...state.company, companyImage: action.payload }
          : currentCompany
      );

      return {
        ...state,
        companyImage: action.payload,
        companies: newCompanies,
      };
    }
    case "UPDATE_COMPANY_IMAGE": {
      return {
        ...state,
        companyImage: action.payload,
      };
    }
    case "UPDATE_COMPANY_USER": {
      return {
        ...state,
        companyUser: action.payload,
      };
    }

    case "GET_COMPANY_USERS": {
      return {
        ...state,
        users: action.payload,
      };
    }

    case "UPDATE_COMPANY_USERS": {
      const newUsers = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
      if (!newUsers.find((user) => user.id === action.payload.id)) {
        newUsers.push(action.payload);
      }
      return {
        ...state,
        users: newUsers,
      };
    }

    case "REMOVE_COMPANY_USER": {
      const newUsers = state.users.filter((user) => user.id !== action.payload);
      return {
        ...state,
        users: newUsers,
      };
    }

    case "GET_COMPANY_FAVORITES": {
      return {
        ...state,
        favorites: action.payload,
      };
    }

    case "GET_STAMP_CLOCK_LOCAL_ONLY_SETTING": {
      return {
        ...state,
        localOnly: action.payload,
      };
    }

    case "GET_ALLOW_AUTOGENERATE_SHIFT_SETTING": {
      return {
        ...state,
        allowAutoGenerate: action.payload,
      };
    }

    case "ADD_COMPANY_FAVORITE": {
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      };
    }

    case "REMOVE_COMPANY_FAVORITE": {
      const newFavorites = state.favorites.filter((favorite) => {
        return favorite.workerId !== action.payload;
      });
      return {
        ...state,
        favorites: newFavorites,
      };
    }
    case "UPDATE_COMPANY_OBSETTINGS": {
      return {
        ...state,
        obSettings: action.payload,
      };
    }
    case "GET_WORKER_DETAILS": {
      return {
        ...state,
        worker: action.payload,
      };
    }
    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_IMAGE_IS_LOADING": {
      return { ...state, imageIsLoading: action.payload };
    }
    case "SET_REQUEST_ERROR": {
      return { ...state, error: action.payload };
    }
    case "GET_TIME_REPORT_FOR_STAMPING": {
      return {
        ...state,
        timeReportForStamping: action.payload,
      };
    }
    case "GET_STAMP_CLOCK_TEMPLATE": {
      return {
        ...state,
        template: action.payload,
      };
    }
    case "HANDLE_CHECKIN_CHECKOUT_USER": {
      return {
        ...state,
        userWithTimeReportForStampClock: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
