import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { H3 } from "../../../../../components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "../../../../../components/pageLayouts/pageLayouts.styled";
import { useCompanyStore } from "../../../stores/companyStore/companyStore";
import { useAuthStore } from "../../../../../stores/authStore/authStore";
import { FavoriteDetail } from "../../../components/profile/favorite/favoriteDetail.component";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { CompanyFavoriteDto } from "model/Favorite";

type MatchProps = {
  id?: string;
};

export const FavoriteDetailPage: React.FC<RouteComponentProps<MatchProps>> = (
  props
) => {
  const [companyState] = useCompanyStore();
  const [authState] = useAuthStore();
  const id = props.match.params.id;
  const [favorite, setFavorite] = useState<CompanyFavoriteDto>();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      const matching = companyState.favorites.find(
        (favorite) => favorite.workerId === parseInt(id)
      );
      setFavorite(matching);
    }
  }, [id, companyState.favorites]);

  useEffect(() => {
    if (
      companyState.company?.id &&
      favorite &&
      favorite.companyId !== companyState.company?.id
    ) {
      history.goBack();
    }
  }, [companyState.company?.id, favorite]);

  return (
    <>
      {authState.isLoading || companyState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          {favorite && (
            <>
              <H3>
                {favorite.firstName} {favorite.lastName}
              </H3>
              <FavoriteDetail favorite={favorite} />
            </>
          )}
        </LoggedInPageContainer>
      )}
    </>
  );
};
