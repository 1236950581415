import React, { useEffect, useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { DropDownContainer, LinkWrapper, ModalBody } from "./workgroup.styled";
import { WorkGroupRate } from "./workgroupRate.component";
import { Dropdown } from "components/dropdown/Dropdown";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  Entity,
  EntityWithUnit,
  WorkerClassification,
  WorkGroup,
} from "model/Workgroup";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useHistory } from "react-router-dom";
import { Api } from "services/api/api.service";

type Props = {
  setShowAddWorkerModal: React.Dispatch<React.SetStateAction<boolean>>;
  showAddWorkerModal: boolean;
  workGroups: WorkGroup[];
  fromDetails?: boolean;
};

export const WorkGroupAddWorkerModal: React.FC<Props> = ({
  setShowAddWorkerModal,
  showAddWorkerModal,
  workGroups,
  fromDetails,
}) => {
  const { t } = useTranslation();
  const [hourlyRate, setHourlyRate] = useState<string>("0");
  const [monthlyRate, setMonthlyRate] = useState<string>("0");
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<number | null>(
    fromDetails ? workGroups[0].id : null
  );
  const [entitiesWithUnits, setEntitiesWithUnits] = useState<EntityWithUnit[]>(
    []
  );
  const [workers, setWorkers] = useState<[]>([]);
  const [companyState, ,] = useCompanyStore();
  const [selectedUnit, setSelectedUnit] = useState<
    EntityWithUnit | undefined
  >();
  const [selectedWorker, setSelectedWorker] = useState<number>();
  const [, workgroupActions] = useWorkgroupStore();
  const [state] = useWorkgroupStore();
  const [errorText, setErrorText] = useState<string>();
  const [, , alertDispatch] = useAlertStore();
  const history = useHistory();
  const [entities, setEntities] = useState<Entity[]>([]);

  const [selectedWorkerClassification, setSelectedWorkerClassification] =
    useState<string | null>(null);

  const workerClassifications = Object.entries(WorkerClassification)
    .filter(([key, value]) => isNaN(Number(key)))
    .map(([key, value]) => ({ value, name: key }));

  const handleAdd = () => {
    if (
      companyState.company &&
      selectedWorkGroup &&
      selectedWorker &&
      hourlyRate &&
      monthlyRate &&
      selectedWorkerClassification
    ) {
      workgroupActions
        .addWorkerToWorkGroup(
          companyState.company.id,
          selectedWorkGroup,
          selectedWorker,
          parseInt(hourlyRate),
          parseInt(monthlyRate),
          selectedWorkerClassification
        )
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("WorkGroup.Added"),
              message: t("WorkGroup.WorkerWasAddedToWorkGroup"),
            },
          });
          setShowAddWorkerModal(false);
        })
        .catch((err: any) => {
          console.log(err);
          if (
            err.response.data.errorMessage ==
            "Worker already added to Work Group"
          ) {
            setErrorText(t("WorkGroup.WorkerAlreadyAddedToWorkGroup"));
          }
        });
    }
  };

  useEffect(() => {
    if (companyState.company) {
      Api()
        .company.workgroup.getEntities(companyState.company.id)
        .then((res) => {
          setEntities(res.data.entities);
        })
        .catch(() => {
          console.log("Error fetching entities");
        });
    }
  }, [companyState.company]);

  useEffect(() => {
    if (entities) {
      entities.forEach((entity) => {
        entity.units.forEach((unit) => {
          setEntitiesWithUnits((prevList) => [
            ...prevList,
            { key: `${unit.name}`, value: unit.workers },
          ]);
        });
      });
    }
  }, [entities]);

  useEffect(() => {
    if (entitiesWithUnits.length == 1) {
      setSelectedUnit(entitiesWithUnits[0]);
      setWorkers(entitiesWithUnits[0].value);
    }
  }, [entitiesWithUnits]);

  return (
    <>
      <Modal
        open={showAddWorkerModal}
        onClose={() => {
          setShowAddWorkerModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowAddWorkerModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H3}
          >
            {!fromDetails
              ? t("WorkGroup.Add")
              : t("WorkGroup.AddTo", {
                  groupName: workGroups[0].name,
                })}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ marginBottom: 10 }}
          >
            {t("WorkGroup.AddWorkerByUnit")}
          </Text>
          <Text
            style={{ marginTop: 10, marginBottom: 5 }}
            fontSize={FontSize.Small}
          >
            {t("WorkGroup.Unit")}
          </Text>
          <DropDownContainer style={{ marginBottom: 10 }}>
            <Dropdown
              disabled={entitiesWithUnits.length == 1}
              textColor={Color.MidnightBlue}
              smallerFont
              placeholder={t("WorkGroup.Unit")}
              activeValue={
                entitiesWithUnits.length == 1
                  ? entitiesWithUnits[0].key
                  : selectedUnit?.key
                  ? `${selectedUnit.key}`
                  : undefined
              }
              items={entitiesWithUnits.map((unit) => ({
                value: unit.key,
                label: unit.key,
              }))}
              maxHeight={200}
              onChange={(e: any) => {
                setSelectedUnit(e);
                const selectedWorkers = entitiesWithUnits.find(
                  (unit) => unit.key === e.value
                );
                if (selectedWorkers) {
                  setWorkers(selectedWorkers.value);
                }
              }}
            />
          </DropDownContainer>
          <Text
            style={{ marginTop: 10, marginBottom: 5 }}
            fontSize={FontSize.Small}
          >
            {t("WorkGroup.Worker")}
          </Text>
          <DropDownContainer style={{ marginBottom: 10 }}>
            <Dropdown
              disabled={workers.length == 0}
              textColor={Color.MidnightBlue}
              smallerFont
              placeholder={t("WorkGroup.Worker")}
              activeValue={selectedWorker ? selectedWorker : undefined}
              items={workers.map((m: any) => ({
                value: `${m.id}`,
                label: `${m.firstName} ${m.lastName}`,
              }))}
              maxHeight={200}
              onChange={(e: any) => {
                setSelectedWorker(e.value);
              }}
            />
          </DropDownContainer>
          {workers.length == 0 && selectedUnit && (
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Tiny}
              color={Color.LighterDestructive}
              style={{
                cursor: "pointer",
                width: "80%",
                marginLeft: 5,
                marginBottom: 0,
              }}
              onClick={() => history.push(`settings?page=structure`)}
            >
              {t("WorkGroup.YouDontHaveAnyWorkersAddHere")}
            </Text>
          )}
          <Text
            style={{ marginTop: 10, marginBottom: 0 }}
            fontSize={FontSize.Small}
          >
            {t("WorkGroup.WorkerClassification")}
          </Text>
          <DropDownContainer style={{ marginTop: 10 }}>
            <Dropdown
              disabled={fromDetails}
              smallerFont
              textColor={Color.MidnightBlue}
              placeholder={t("WorkGroup.WorkerClassification")}
              activeValue={
                selectedWorkerClassification
                  ? selectedWorkerClassification
                  : undefined
              }
              items={workerClassifications.map((m: any) => ({
                value: m.name,
                label: t(`WorkGroup.${m.name}`),
                description: t(`WorkGroup.${m.name + 'Description'}`)
              }))}
              maxHeight={200}
              onChange={(e: any) => {
                setSelectedWorkerClassification(e.value);
              }}
              
            />
          </DropDownContainer>
          {!fromDetails && (
            <>
              <Text
                style={{ marginTop: 10, marginBottom: 5 }}
                fontSize={FontSize.Small}
              >
                {t("WorkGroup.WorkGroup")}
              </Text>
              <DropDownContainer>
                <Dropdown
                  disabled={fromDetails}
                  textColor={Color.MidnightBlue}
                  smallerFont
                  placeholder={t("WorkGroup.WorkGroup")}
                  activeValue={
                    selectedWorkGroup ? selectedWorkGroup : undefined
                  }
                  items={workGroups.map((m: any) => ({
                    value: m.id,
                    label: m.name,
                    id: m.id,
                  }))}
                  maxHeight={200}
                  onChange={(e: any) => {
                    setSelectedWorkGroup(e.id);
                  }}
                />
              </DropDownContainer>
            </>
          )}
          <WorkGroupRate
            monthlyRate={monthlyRate}
            setMonthlyRate={setMonthlyRate}
            hourlyRate={hourlyRate}
            setHourlyRate={setHourlyRate}
          />
          {errorText && (
            <Text
              color={Color.LighterDestructive}
              style={{ margin: "0 auto", marginTop: 20 }}
            >
              {errorText}
            </Text>
          )}
          <ButtonStyled
            disabled={!selectedWorkGroup || !selectedWorker}
            style={{ marginTop: 40 }}
            backgroundColor={Color.ModernGreen}
            onClick={() => {
              handleAdd();
            }}
          >
            {t("WorkGroup.Add")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
