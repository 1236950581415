import { useState, useEffect } from "react";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import {
  Wrapper,
  Select,
  SelectHeader,
  Options,
  SpinnerWrapper,
} from "./Dropdown.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { CircularProgress } from "@material-ui/core";
import { Color } from "config/colors";
import { DropdownItem } from "./DropdownItem";

export type ListViewModel = {
  value: string | number;
  label: string;
  description?:string;
};

interface DropdownProps {
  items: ListViewModel[];
  activeValue?: string | number;
  placeholder?: string;
  disabled?: boolean;
  showSpinner?: boolean;
  maxHeight?: number;
  onChange?: (vm: ListViewModel) => void;
  onRemove?: (vm: ListViewModel) => void;
  onDetails?: (vm: ListViewModel) => void;
  onScrollBottom?: () => void;
  smallerHeight?: boolean;
  smallerFont?: boolean;
  textColor?: Color;
  disabledItems?: ListViewModel[];
  isCalendar?: boolean;
}

export const Dropdown = ({
  items,
  activeValue,
  placeholder,
  disabled,
  showSpinner,
  maxHeight,
  onChange,
  onRemove,
  onDetails,
  onScrollBottom,
  smallerHeight,
  smallerFont,
  textColor,
  disabledItems,
  isCalendar,
}: DropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ListViewModel>({
    value: "",
    label: placeholder ?? "",
  });

  useEffect(() => {
    const item = items.find((item) => item.value === activeValue);
    if (item) {
      setSelectedItem(item);
    } else {
      setSelectedItem(
        placeholder
          ? {
              value: "",
              label: placeholder ?? "",
            }
          : items?.[0]
      );
    }
  }, [activeValue]);

  const onItemSelected = (vm: ListViewModel) => {
    setSelectedItem(vm);
    setIsVisible(false);
    onChange?.(vm);
  };

  const onItemRemove = (vm: ListViewModel) => {
    setSelectedItem(
      placeholder
        ? {
            value: "",
            label: placeholder ?? "",
          }
        : items?.[0]
    );
    setIsVisible(false);
    onRemove?.(vm);
  };

  const onItemDetails = (vm: ListViewModel) => {
    setIsVisible(false);
    onDetails?.(vm);
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!onScrollBottom || disabled) return;

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    const threshold = 1.1;
    const bottom = scrollHeight - scrollTop <= clientHeight * threshold;

    if (bottom) {
      onScrollBottom();
    }
  };

  return (
    <Wrapper aria-disabled={disabled} isDisabled={disabled}>
      <Select
        smallerHeight={smallerHeight}
        isDisabled={disabled}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        <SelectHeader isDisabled={showSpinner}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={smallerFont ? FontSize.Standard : FontSize.Large}
            color={textColor ? textColor : Color.BurntSienna}
          >
            {selectedItem.label}
          </Text>
          <CustomIcon
            name={isVisible ? "chevron-up" : "chevron-down"}
            size={smallerFont ? "35px" : "44px"}
            color={textColor ? textColor : Color.BurntSienna}
            padding={smallerFont ? "8px" : "10px"}
            hover
            style={{ marginBottom: isCalendar ? 0 : 27}}
          />
        </SelectHeader>
        {showSpinner && (
          <SpinnerWrapper>
            <CircularProgress size={24} style={{ color: Color.BurntSienna }} />
          </SpinnerWrapper>
        )}
      </Select>

      <Options
        onScroll={onScroll}
        isVisible={isVisible}
        maxHeight={maxHeight}
        isDisabled={disabled}
      >
        {items.map((vm) => (
          <DropdownItem
            key={vm.value}
            model={vm}
            isSelected={vm.value === selectedItem.value}
            onClickItem={onItemSelected}
            onRemoveItem={onRemove ? onItemRemove : undefined}
            onClickDetails={onDetails ? onItemDetails : undefined}
            smallerFont
            disabled={
              disabledItems &&
              disabledItems.find((dItem) => dItem.value === vm.value)
                ? true
                : false
            }
          
          />
        ))}
      </Options>
    </Wrapper>
  );
};
