import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  RoleAndSkillsContainer,
  DropDownRow,
  Row,
} from "./gigDetail.styled";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { Entity, EntityWithUnitId } from "model/Workgroup";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

type Props = {
  gigData: CompanyGigDto;
};

export const GigDetailUnitAndWorkGroup: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [unitAndWorkgroupOpen, setUnitAndWorkgroupOpen] = useState(false);
  const [state] = useWorkgroupStore();
  const [workGroup, setWorkGroup] = useState<string>();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<EntityWithUnitId>();
  const [entitiesWithUnits, setEntitiesWithUnits] = useState<
    EntityWithUnitId[]
  >([]);
  const [companyState] = useCompanyStore();

  useEffect(() => {
    if (gigData.workGroupId) {
      const matchingGroup = state.workgroups.find(
        (group) => group.id === gigData.workGroupId
      );
      if (matchingGroup) {
        setWorkGroup(matchingGroup.name);
      }
    }
  }, []);

  useEffect(() => {
    if (companyState.company) {
      Api()
        .company.workgroup.getEntitiesWithoutWorkers(companyState.company.id)
        .then((res) => {
          setEntities(res.data.entities);
        })
        .catch(() => {
          console.log("Error fetching entities");
        });
    }
  }, [companyState.company]);

  useEffect(() => {
    if (entities) {
      entities.forEach((entity) => {
        entity.units.forEach((unit) => {
          setEntitiesWithUnits((prevList) => [
            ...prevList,
            { key: `${unit.name}`, value: unit.id },
          ]);
        });
      });
    }
  }, [entities]);

  useEffect(() => {
    if (gigData.companyUnitId) {
      const matchingUnit = entitiesWithUnits.find(
        (unit) => unit.value === gigData.companyUnitId
      );

      if (matchingUnit) {
        setSelectedUnit(matchingUnit);
      }
    }
  }, [entitiesWithUnits, gigData]);

  return (
    <>
      <DropDownRow
        onClick={() => {
          setUnitAndWorkgroupOpen(!unitAndWorkgroupOpen);
        }}
        style={{ marginTop: 20 }}
      >
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          {gigData.workGroupId
            ? t("GigsCompany.UnitAndWorkGroup")
            : t("GigsCompany.Unit")}
        </Text>
        <CustomIcon
          name={unitAndWorkgroupOpen ? "upward_arrow" : "downward_arrow"}
          size="24px"
          color={Color.BurntSienna}
          style={{ marginTop: 10 }}
        />
      </DropDownRow>
      {unitAndWorkgroupOpen && (
        <RoleAndSkillsContainer>
          {gigData.companyUnitId && selectedUnit && (
            <Row>
              <Text
                fontSize={FontSize.Standard}
                fontFamily={FontFamily.MontserratRegular}
              >
                {t("GigsCompany.Unit")}
              </Text>
              <Text fontSize={FontSize.Standard}>{selectedUnit.key}</Text>
            </Row>
          )}
          {gigData.workGroupId && workGroup && (
            <Row>
              <Text
                fontSize={FontSize.Standard}
                fontFamily={FontFamily.MontserratRegular}
              >
                {t("GigsCompany.WorkGroup")}
              </Text>
              <Text fontSize={FontSize.Standard}>{workGroup}</Text>
            </Row>
          )}
        </RoleAndSkillsContainer>
      )}
    </>
  );
};
