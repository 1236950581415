import { Entity } from "model/CompanyStructure";
import { EntityAndUnit, UnitWithId, WorkGroup } from "model/Workgroup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Dropdown } from "components/dropdown/Dropdown";
import { Color } from "config/colors";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import {
  DropDownContainer,
  FilterCalendarContainer,
  FilterColumn,
} from "./weeklyCalendar.styled";
import { Filter, FilterType, GroupType } from "model/Calendar";
import { LabelStyled } from "../clockStamp/clockStamp.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";

type Props = {
  setFilter: React.Dispatch<React.SetStateAction<Filter[]>>;
};

export const FilterCalendar: React.FC<Props> = ({ setFilter }) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [entitiesWithUnits, setEntitiesWithUnits] = useState<EntityAndUnit[]>(
    []
  );
  const [units, setUnits] = useState<UnitWithId[]>([]);
  const [workgroups, setWorkgroups] = useState<WorkGroup[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<EntityAndUnit>();
  const [selectedUnit, setSelectedUnit] = useState<UnitWithId>();
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<WorkGroup>();
  const [selectedGroupBy, setSelectedGroupBy] = useState<GroupType>(
    GroupType.NONE
  );
  const [state] = useWorkgroupStore();
  const [filterType, setFilterType] = useState<FilterType>(FilterType.NONE);
  const [localFilter, setLocalFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);

  const fetchEntities = () => {
    if (companyState.company) {
      Api()
        .company.workgroup.getEntitiesWithoutWorkers(companyState.company.id)
        .then((res) => {
          setEntities(res.data.entities);
        })
        .catch(() => {
          console.log("Error fetching entities");
        });
    }
  };

  const createListWithEntitiesAndUnits = () => {
    if (entitiesWithUnits.length == 0) {
      entities.forEach((entity) => {
        setEntitiesWithUnits((prevList: any) => [
          ...prevList,
          { key: `${entity.name}`, units: entity.units, entity: entity },
        ]);
        entity.units.forEach((unit) => {
          setUnits((prevList) => [
            ...prevList,
            { key: `${unit.name}`, value: unit.id },
          ]);
        });
      });
    }
  };

  useEffect(() => {
    if (entities && filterType == FilterType.COMPANYUNIT) {
      createListWithEntitiesAndUnits();
    }
  }, [entities]);

  useEffect(() => {
    if (
      (filterType == FilterType.COMPANYENTITY ||
        filterType == FilterType.COMPANYUNIT) &&
      entities.length == 0
    ) {
      fetchEntities();
    } else if (filterType == FilterType.COMPANYUNIT && entities.length !== 0) {
      createListWithEntitiesAndUnits();
    }
  }, [filterType]);

  useEffect(() => {
    if (companyState.company && state.workgroups) {
      setWorkgroups(state.workgroups);
    }
  }, [companyState.company, state.workgroups]);

  return (
    <>
      <FilterCalendarContainer>
        <FilterColumn>
          <LabelStyled style={{ marginTop: 10, marginBottom: 4 }}>
            {t("Calendar.GroupBy")}
          </LabelStyled>
          <DropDownContainer>
            <Dropdown
              isCalendar
              textColor={Color.MidnightBlue}
              smallerFont
              placeholder={t("Calendar.GroupBy")}
              activeValue={selectedGroupBy ? selectedGroupBy : undefined}
              items={[
                { value: GroupType.UNIT, label: t("WorkGroup.Unit") },
                { value: GroupType.WORKGROUP, label: t("WorkGroup.WorkGroup") },
                { value: GroupType.NONE, label: t("Calendar.None") },
              ]}
              maxHeight={200}
              onChange={(e: any) => {
                setSelectedGroupBy(e.value);
                setFilter([
                  {
                    filterId: -1,
                    filterType: FilterType.NONE,
                    groupType: e.value,
                  },
                ]);
              }}
            />
          </DropDownContainer>
        </FilterColumn>
        <FilterColumn>
          <LabelStyled style={{ marginTop: 10, marginBottom: 4 }}>
            {t("Calendar.Filter")}
          </LabelStyled>
          <DropDownContainer>
            <Dropdown
              isCalendar
              textColor={Color.MidnightBlue}
              smallerFont
              placeholder={t("Calendar.Filter")}
              activeValue={filterType ? filterType : undefined}
              items={[
                { value: FilterType.COMPANYUNIT, label: t("WorkGroup.Unit") },
                {
                  value: FilterType.COMPANYWORKGROUP,
                  label: t("WorkGroup.WorkGroup"),
                },
                { value: FilterType.NONE, label: t("Calendar.None") },
              ]}
              maxHeight={200}
              onChange={(e: any) => {
                setFilterType(e.value);
                setLocalFilter([
                  {
                    filterId:
                      filterType == FilterType.COMPANYWORKGROUP &&
                      selectedWorkGroup
                        ? selectedWorkGroup.id
                        : filterType == FilterType.COMPANYUNIT && selectedUnit
                        ? selectedUnit.value
                        : filterType == FilterType.COMPANYENTITY &&
                          selectedEntity
                        ? selectedEntity.entity.id
                        : -1,
                    filterType: e.value,
                    groupType: selectedGroupBy
                      ? selectedGroupBy
                      : GroupType.NONE,
                  },
                ]);
              }}
            />
          </DropDownContainer>
        </FilterColumn>
        {filterType == FilterType.COMPANYENTITY && (
          <FilterColumn>
            <LabelStyled style={{ marginTop: 10, marginBottom: 4 }}>
              {t("Calendar.Entity")}
            </LabelStyled>
            <DropDownContainer>
              <Dropdown
                isCalendar
                disabled={entitiesWithUnits.length == 1}
                textColor={Color.MidnightBlue}
                smallerFont
                placeholder={t("Calendar.ChooseEntity")}
                activeValue={selectedEntity ? selectedEntity.key : undefined}
                items={entities.map((entity) => ({
                  value: entity.name,
                  label: entity.name,
                  id: entity.id,
                }))}
                maxHeight={200}
                onChange={(e: any) => {
                  setSelectedEntity(e);
                  setLocalFilter([
                    {
                      filterId: e.id,
                      filterType: FilterType.COMPANYENTITY,
                      groupType: selectedGroupBy
                        ? selectedGroupBy
                        : GroupType.NONE,
                    },
                  ]);
                }}
              />
            </DropDownContainer>
          </FilterColumn>
        )}
        {filterType == FilterType.COMPANYUNIT && (
          <FilterColumn>
            <LabelStyled style={{ marginTop: 10, marginBottom: 4 }}>
              {t("WorkGroup.Unit")}
            </LabelStyled>
            <DropDownContainer>
              <Dropdown
                isCalendar
                disabled={units.length == 1}
                textColor={Color.MidnightBlue}
                smallerFont
                placeholder={t("Calendar.ChooseUnit")}
                activeValue={
                  selectedUnit?.key ? `${selectedUnit.key}` : undefined
                }
                items={units.map((unit) => ({
                  value: unit.value,
                  label: unit.key,
                }))}
                maxHeight={200}
                onChange={(e: any) => {
                  setSelectedUnit(e);
                  setLocalFilter([
                    {
                      filterId: e.value,
                      filterType: FilterType.COMPANYUNIT,
                      groupType: selectedGroupBy
                        ? selectedGroupBy
                        : GroupType.NONE,
                    },
                  ]);
                }}
              />
            </DropDownContainer>
          </FilterColumn>
        )}
        {filterType == FilterType.COMPANYWORKGROUP && (
          <FilterColumn>
            <LabelStyled style={{ marginTop: 10, marginBottom: 4 }}>
              {t("WorkGroup.WorkGroup")}
            </LabelStyled>
            <DropDownContainer>
              <Dropdown
                isCalendar
                disabled={workgroups.length == 0}
                textColor={Color.MidnightBlue}
                smallerFont
                placeholder={t("Calendar.ChooseWorkgroup")}
                activeValue={
                  selectedWorkGroup?.name ? selectedWorkGroup.name : undefined
                }
                items={workgroups.map((workgroup) => ({
                  value: workgroup.name,
                  label: workgroup.name,
                  id: workgroup.id,
                }))}
                maxHeight={200}
                onChange={(e: any) => {
                  setSelectedWorkGroup(e);
                  setLocalFilter([
                    {
                      filterId: e.id,
                      filterType: FilterType.COMPANYWORKGROUP,
                      groupType: selectedGroupBy
                        ? selectedGroupBy
                        : GroupType.NONE,
                    },
                  ]);
                }}
              />
            </DropDownContainer>
          </FilterColumn>
        )}
        <FilterColumn>
          <ButtonStyled
            onClick={() => setFilter(localFilter)}
            backgroundColor={Color.ModernGreen}
            color={Color.White}
          >
            {t("CompanyTimeReport.Filter")}
          </ButtonStyled>
        </FilterColumn>
      </FilterCalendarContainer>
    </>
  );
};
