import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApplicationDto,
  ApplicationRequestDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  ButtonActionContainer1,
  ButtonActionContainer2,
  ButtonsRow,
  Cell,
  CheckmarkContainer,
  DividerLine3,
  GigInfoContainer,
  RowLink,
  TagRow,
  WorkerInfoContainer,
} from "./applicationList.styled";
import {
  ApplicantImageWrapper,
  RowWrapper,
  WithdrawOfferModalBody,
} from "../gigApplicants/gigApplicants.styled";
import { DividerLine1, DividerLine2, SmallImage } from "../gig/gigs.styled";
import { Modal } from "@material-ui/core";
import { getWorkerImg } from "services/firebase/firebase.service";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import moment from "moment";
import { ApplicantDetailsModal } from "../applicantDetails/applicantDetailsModal.component";
import {
  IconCircularContainer,
  IconsContainer,
} from "../dashboard/dashboard.styled";

type Props = {
  application: ApplicationDto;
  handleShowContract: (application: ApplicationDto) => void;
  selectApplications: boolean;
  setSelectedAppliations: React.Dispatch<
    React.SetStateAction<ApplicationDto[]>
  >;
  selectedApplications: ApplicationDto[];
  pending?: boolean;
};

export const ApplicationListItem: React.FC<Props> = ({
  application,
  handleShowContract,
  selectApplications,
  selectedApplications,
  setSelectedAppliations,
  pending,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [applicantImg, setApplicantImg] = useState<string | null>(null);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [companyState] = useCompanyStore();
  const [applicationState, applicationActions, applicationDispatch] =
    useApplicationStore();
  const [, , alertDispatch] = useAlertStore();
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (application.worker.firebaseId) {
      getWorkerImg(application.worker.firebaseId).then((res) => {
        setApplicantImg(res);
      });
    }
  }, []);

  useEffect(() => {
    if (selected) {
      setSelectedAppliations([...selectedApplications, application]);
    } else {
      setSelectedAppliations(
        selectedApplications.filter((app) => app.id !== application.id)
      );
    }
  }, [selected]);

  const withdrawOffer = () => {
    const req: ApplicationRequestDto = {
      ...application,
      status: ApplicationStatus.CLOSED,
      resolution: ApplicationResolution.WITHDRAWN,
    };

    if (companyState.company) {
      applicationActions
        .updateApplicationStatus(companyState.company.id, application.id, req)
        .then((res) => {
          setWithdrawModalOpen(false);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("GigsCompany.Withdrawn"),
              message: t("GigsCompany.OfferWithdrawn"),
            },
          });
        })
        .catch((err) => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "cross", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("Alerts.SomethingWentWrong"),
            },
          });
        });
    }
  };

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED,
    applicant: ApplicationDto
  ) => {
    if (companyState.company) {
      const data = {
        ...applicant,
        status,
        ...(status === ApplicationStatus.CLOSED && {
          resolution: ApplicationResolution.REJECTED,
        }),
      };
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicant.id, data)
        .then(() => {
          applicationDispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: applicationState.applicationsCount - 1,
          });
          if (status === ApplicationStatus.OFFERED) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("ApplicationsCompany.Offered"),
                message: t("ApplicationsCompany.ApplicantHasBeenOfferedGig"),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("ApplicationsCompany.Rejected"),
                message: t("ApplicationsCompany.ApplicationHasBeenRejected"),
              },
            });
          }
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
            },
          });
        });
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("GigsCompany.WithdrawOffer")}
        </Text>

        <RowWrapper>
          <Text
            fontSize={FontSize.Large}
            color={Color.SeaBlue600}
            style={{ paddingTop: 25 }}
          >
            {t("GigsCompany.AreYouSureWithdrawOffer")}
          </Text>
        </RowWrapper>

        <RowWrapper>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setWithdrawModalOpen(false);
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            onClick={() => {
              withdrawOffer();
            }}
          >
            {t("GigsCompany.WithdrawOffer")}
          </ButtonStyled>
        </RowWrapper>
      </>
    );
  };

  return (
    <>
      <Cell pending={pending}>
        <WorkerInfoContainer>
          <RowLink onClick={() => setShowApplicationDetails(true)}>
            <ApplicantImageWrapper>
              {applicantImg ? (
                <SmallImage src={applicantImg} />
              ) : (
                <CustomIcon
                  name="person"
                  size="100%"
                  color={Color.SeaBlue600}
                  padding="10px"
                />
              )}
            </ApplicantImageWrapper>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
              style={{ textAlign: "left" }}
            >
              {application.worker.firstName} {application.worker.lastName}
            </Text>
            {pending && (
              <TagRow>
                <Text
                  color={Color.White}
                  backgroundColor={Color.SeaBlue600}
                  padding
                  style={{ marginLeft: "4px", marginRight: "4px" }}
                >
                  {`${application.requestedRate} ${t("General.Currency")}`}
                </Text>
                {application.worker.stats &&
                  application.worker.stats.some(
                    (stat: any) => stat.stat === "Rating"
                  ) && (
                    <Text
                      color={Color.White}
                      backgroundColor={Color.SeaBlue600}
                      padding
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {application.worker.stats
                        .find((stat: any) => stat.stat === "Rating")
                        .value.toFixed(1) + " / 5"}{" "}
                      <CustomIcon
                        name="star-filled"
                        color={Color.White}
                        size="16px"
                        style={{ marginLeft: "4px" }}
                      />
                    </Text>
                  )}
              </TagRow>
            )}
          </RowLink>
        </WorkerInfoContainer>
        <DividerLine1 />
        <GigInfoContainer>
          <RowLink
            onClick={() =>
              history.push(
                `./applications/${application.gigId}?status=Accepted`
              )
            }
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <Text
              style={{ textOverflow: "ellipsis", marginBottom: 4 }}
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.Large}
              color={Color.MidnightBlue}
            >
              {`${moment(application.gig.startTime).format("D/M")} ${
                application.gig.role
              } `}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.PitchBlack}
            >
              {`${application.gig.hiredWorkerCount} / ${
                application.gig.amountOfWorkers
              } ${t("ApplicationsCompany.Hired")}`}
            </Text>
          </RowLink>
        </GigInfoContainer>
        <DividerLine2 />
        <ButtonActionContainer1>
          <ButtonsRow>
            <TagRow>
              {handleShowContract && (
                <IconsContainer>
                  <IconCircularContainer
                    backgroundColor={Color.MidnightBlue}
                    onClick={() => {
                      handleShowContract(application);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    <CustomIcon
                      style={{ zIndex: 1000 }}
                      name="document"
                      size="24px"
                      padding="2px"
                      color={Color.White}
                    />
                  </IconCircularContainer>
                </IconsContainer>
              )}

              <IconsContainer>
                <IconCircularContainer
                  backgroundColor={Color.MidnightBlue}
                  onClick={() => {
                    history.push(
                      `/company/chat?userId=${application.worker.id}&gigId=${application.gigId}&applicationId=${application.id}`
                    );
                  }}
                  style={{ marginRight: 10 }}
                >
                  <CustomIcon
                    style={{ zIndex: 1000 }}
                    name="chat"
                    size="24px"
                    padding="2px"
                    color={Color.White}
                  />
                </IconCircularContainer>
              </IconsContainer>
            </TagRow>
          </ButtonsRow>
        </ButtonActionContainer1>
        <DividerLine3 />
        <ButtonActionContainer2>
          <ButtonsRow>
            {application.status === ApplicationStatus.PENDING &&
              !selectApplications && (
                <>
                  <IconsContainer>
                    <IconCircularContainer
                      isAccept
                      onClick={() => {
                        updateApplicationStatus(
                          ApplicationStatus.OFFERED,
                          application
                        );
                      }}
                    >
                      <CustomIcon
                        style={{ zIndex: 1000 }}
                        name="checkmark"
                        size="24px"
                        padding="2px"
                        color={Color.White}
                      />
                    </IconCircularContainer>
                    <IconCircularContainer
                      onClick={() => {
                        updateApplicationStatus(
                          ApplicationStatus.CLOSED,
                          application
                        );
                      }}
                    >
                      <CustomIcon
                        style={{ zIndex: 1000 }}
                        name="cross"
                        size="24px"
                        padding="2px"
                        color={Color.White}
                      />
                    </IconCircularContainer>
                  </IconsContainer>
                </>
              )}
            {application.status === ApplicationStatus.PENDING &&
              selectApplications && (
                <CheckmarkContainer onClick={() => setSelected(!selected)}>
                  {selected && (
                    <CustomIcon
                      name="checkmark"
                      size="34px"
                      color={Color.BurntSienna}
                      style={{
                        flexShrink: 0,
                      }}
                    />
                  )}
                </CheckmarkContainer>
              )}

            {application.status === ApplicationStatus.OFFERED && (
              <Text
                backgroundColor={Color.Positive}
                color={Color.BurntSienna}
                fontSize={FontSize.Small}
                padding
                style={{ marginRight: 10 }}
              >
                {t("GigsCompany.Offered")}
              </Text>
            )}
            {application.status === ApplicationStatus.CLOSED &&
              application.resolution === ApplicationResolution.ACCEPTED && (
                <Text
                  backgroundColor={Color.Positive}
                  color={Color.BurntSienna}
                  fontSize={FontSize.Small}
                  padding
                  style={{ marginRight: 10 }}
                >
                  {t("ApplicationsCompany.Hired")}
                </Text>
              )}
            {application.status === ApplicationStatus.CLOSED &&
              (application.resolution === ApplicationResolution.REJECTED ||
                application.resolution === ApplicationResolution.WITHDRAWN ||
                application.resolution === ApplicationResolution.DECLINED) && (
                <>
                  <Text
                    color={Color.LighterDestructive}
                    backgroundColor={Color.Warning}
                    padding
                    fontSize={FontSize.Small}
                    style={{ marginRight: 10 }}
                  >
                    {t(`GigsCompany.${application.resolution}`)}
                  </Text>
                </>
              )}

            {application.status === ApplicationStatus.OFFERED && (
              <IconsContainer>
                <IconCircularContainer
                  onClick={() => setWithdrawModalOpen(true)}
                  style={{ marginRight: 10 }}
                >
                  <CustomIcon
                    style={{ zIndex: 1000 }}
                    name="trashcan"
                    size="24px"
                    padding="2px"
                    color={Color.White}
                  />
                </IconCircularContainer>
              </IconsContainer>
            )}
          </ButtonsRow>
        </ButtonActionContainer2>
      </Cell>
      <Modal
        open={withdrawModalOpen}
        onClose={() => {
          setWithdrawModalOpen(false);
        }}
      >
        <WithdrawOfferModalBody>{renderModalBody()}</WithdrawOfferModalBody>
      </Modal>
      {showApplicationDetails && (
        <ApplicantDetailsModal
          applicant={application}
          setShowApplicationModal={setShowApplicationDetails}
          showApplicationModal={showApplicationDetails}
        />
      )}
    </>
  );
};
