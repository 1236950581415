import React, { useEffect, useRef, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, CircularProgress, Modal } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  CompanyGigDto,
  CompanyGigRequestDto,
  CompanyGigSkillRequestDto,
  GigType,
  OfferGigRequest,
  PaymentMethod,
} from "model/Gig";
import { ShiftRequestDto } from "model/Shift";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { AutoCompleteInput } from "components/form/inputs/autoCompleteInput.component";
import { WorkerCounter } from "../workerCounter/workerCounter.component";
import { ShiftForm } from "../shift/shiftForm.component";
import { MultipleShiftsForm } from "../shift/multipleShiftsForm.component";
import { Shifts } from "../shift/shifts.component";
import { HourlyRate } from "../hourlyRate/hourlyRate.component";
import {
  characterCounter,
  CustomLink,
  Text,
} from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled, TextArea } from "components/form/inputs/inputs.styled";
import {
  RoleWrapper,
  LinkWrapper,
  SkillsWrapper,
  SkillInputWrapper,
  SkillTag,
  RowWrapper,
  TemplateWrapper,
  StyledProgress,
  TypeCell,
  TypeRow,
  LoadingWrapper,
  CheckBoxContainer,
  CheckboxText,
} from "./saveGigForm.styled";
import { InputContainer } from "components/form/inputs/inputs.styled";
import { Select } from "components/form/select/select.styled";
import { Roles } from "config/rolesMock";
import { Skills } from "config/skillsMock";
import { getShiftHours, getWorkHours } from "utils/utils";
import { gig } from "services/api/company/gig.service";
import { OfferModal } from "../../gigDetail/offerModal.component";
import { PromptModal } from "components/modal/promptModal.component";
import { Api } from "services/api/api.service";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { ApplicationDto } from "model/Application";
import { Dropdown, ListViewModel } from "components/dropdown/Dropdown";
import { GigTemplateDto } from "model/GigTemplate";
import {
  clamp,
  removeShiftIfDeleteEntry,
  adjustShiftsToWeek,
} from "web-apps/company/utils/utils";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { StyledIcon } from "components/dropdown/DropdownItem.styled";
import { DropDownContainer } from "../../gigDetail/gigDetail.styled";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { Entity, EntityWithUnitId, StaffingPool } from "model/Workgroup";
import { Filter, FilterType, GroupType } from "model/Calendar";

export type Position = {
  id: number;
  positionName: string;
};

type Props = {
  gigData?: CompanyGigDto;
  shiftDate?: string;
  setShowCreateGigModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isClone?: boolean;
  fromCalendar?: boolean;
  onCreateSuccess?: (gigId: number) => void;
  recruit?: boolean;
};

export const SaveGigForm: React.FC<Props> = ({
  gigData,
  shiftDate,
  setShowCreateGigModal,
  isClone,
  fromCalendar,
  onCreateSuccess,
  recruit,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [, calendarActions] = useCalendarStore();
  const [applicationState, , applicationDispatch] = useApplicationStore();
  const [shiftFormOpen, setShiftFormOpen] = useState(false);
  const [templates, setTemplates] = useState<GigTemplateDto[]>([]);
  const [multipleShiftsFormOpen, setMultipleShiftsFormOpen] = useState(false);
  const [skillText, setSkillText] = useState("");
  const [offerList, setOfferList] = useState<
    {
      workerId: number;
      rate: number;
      usingOfferRate: boolean;
      type: "favorite" | "workgroup";
    }[]
  >([]);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);
  const [offersError, setOffersError] = useState<number[]>([]);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const textAreaEl = useRef<HTMLTextAreaElement>(null);
  const [savedGig, setSavedGig] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<GigTemplateDto>();
  const [isPromptModalOpen, setPromptModalOpen] = useState(false);
  const [oldShiftModalOpen, setOldShiftModalOpen] = useState(false);
  const [connectWithParentGig, setConnectWithParentGig] = useState(false);
  const [state] = useWorkgroupStore();
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<number | null>(
    gigData && gigData.workGroupId ? gigData.workGroupId : null
  );
  const [entities, setEntities] = useState<Entity[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<EntityWithUnitId>();
  const [entitiesWithUnits, setEntitiesWithUnits] = useState<
    EntityWithUnitId[]
  >([]);
  const [filter, setFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);
  const [staffingPools, setStaffingPools] = useState<StaffingPool[] | null>([]);

  const haveSimflexityPay = () => {
    return (
      companyState.company?.companySettings &&
      companyState.company.companySettings.find(
        (set) =>
          set.key === "SimflexityPay" && set.value?.toLowerCase() === "true"
      )
    );
  };
  const getPaymentMethod = (): PaymentMethod => {
    if (gigData) {
      return gigData.paymentType || PaymentMethod.NONE;
    } else if (haveSimflexityPay()) {
      return PaymentMethod.SIMFLEXITY_PAY;
    }

    return PaymentMethod.NONE;
  };

  const gigTypes: ListViewModel[] = [
    { value: GigType.PUBLIC, label: t(`GigsCompany.${GigType.PUBLIC}`) },
    { value: GigType.WORKGROUP, label: t(`GigsCompany.${GigType.WORKGROUP}`) },
    { value: GigType.HIDDEN, label: t(`GigsCompany.${GigType.HIDDEN}`) },
    { value: GigType.STAFFING, label: t(`GigsCompany.${GigType.STAFFING}`) },
  ];
  const gigTypes2: any[] = [GigType.PUBLIC, GigType.HIDDEN, GigType.STAFFING];
  const paymentTypes: ListViewModel[] = [
    { value: PaymentMethod.NONE, label: t("GigsCompany.OwnSalaryProcessing") },
    ...(haveSimflexityPay()
      ? [
          {
            value: PaymentMethod.SIMFLEXITY_PAY,
            label: t("SimflexityPay.SimflexityPay"),
          },
        ]
      : []),
  ];

  const [formData, setFormData] = useState<{
    role: string;
    description: string;
    minRate: number;
    maxRate: number;
    amountOfWorkers: number;
    type: GigType;
    paymentType: PaymentMethod;
    skills: CompanyGigSkillRequestDto[];
    shifts: ShiftRequestDto[];
    staffingPoolId?: number;
    workGroupId?: number;
    companyUnitId?: number;
  }>({
    role: gigData?.role || "",
    description: gigData?.description || "",
    minRate: gigData?.minRate || 140,
    maxRate: gigData?.maxRate || 170,
    amountOfWorkers: gigData?.amountOfWorkers || 1,
    type: gigData?.type || (recruit ? GigType.PUBLIC : GigType.HIDDEN),
    paymentType: getPaymentMethod(),
    skills: gigData?.skills || [],
    shifts: gigData?.shifts || [],
  });
  const initialFormData = useRef(formData);

  const {
    description,
    minRate,
    maxRate,
    amountOfWorkers,
    role,
    type,
    paymentType,
    skills,
    shifts,
  } = formData;

  const [offerRate, setOfferRate] = useState(maxRate);
  const apiState = useRef({ loading: false, completed: false, page: 0 });

  const fetchGigTemplates = () => {
    if (
      !companyState.company ||
      apiState.current.loading ||
      apiState.current.completed
    )
      return;

    const templatesToLoad = 10;
    apiState.current.loading = true;
    setIsApiLoading(true);

    Api()
      .company.template.getTemplates(
        companyState.company.id,
        apiState.current.page,
        templatesToLoad
      )
      .then((res) => {
        setTemplates([...templates, ...res.data]);

        if (res.data.length >= templatesToLoad) {
          apiState.current.page++;
        } else {
          apiState.current.completed = true;
        }
      })
      .finally(() => {
        setIsApiLoading(false);
        apiState.current.loading = false;
      });
  };

  useEffect(() => {
    if (!savedGig) {
      setChangesMade(
        JSON.stringify(formData) !== JSON.stringify(initialFormData.current)
      );
    }
  }, [formData]);

  useEffect(() => {
    if (formData.type === GigType.STAFFING && staffingPools) {
      console.log(staffingPools);
      setFormData({
        ...formData,
        workGroupId: staffingPools.length > 0 ? staffingPools[0].id : undefined,
      });
    }
  }, [formData.type]);

  useEffect(() => {
    if (companyState.company) {
      Api()
        .company.workgroup.getEntitiesWithoutWorkers(companyState.company.id)
        .then((res) => {
          setEntities(res.data.entities);
        })
        .catch(() => {
          console.log("Error fetching entities");
        });

      //get staffing pools
      Api()
        .company.staffingWorkerPool.getStaffingPools(companyState.company.id)
        .then((res) => {
          setStaffingPools(res.data);
        })
        .catch(() => {
          console.log("Error fetching staffing pools");
        });
    }
  }, [companyState.company]);

  useEffect(() => {
    const el = textAreaEl.current;
    if (!el) return;
    el.style.height = clamp(el.scrollHeight, 29, 500) + "px";
  }, [description]);

  useEffect(() => {
    fetchGigTemplates();
  }, []);

  const sendOffer = async (data: OfferGigRequest, workerId: number) => {
    const respons = await gig
      .offerGigToWorker(data)
      .then((res) => {
        return res.data.applicationId;
      })
      .then((appid) => {
        if (companyState.company) {
          return Api()
            .company.application.getApplicationById(
              companyState.company?.id,
              appid
            )
            .then((applicationRes) => {
              return applicationRes;
            });
        }
      });

    return respons;
  };

  const onTemplateSelected = (vm: ListViewModel) => {
    const { value } = vm;

    const gigTemplate = templates.find((t) => t.id === value);
    if (gigTemplate) {
      const newShifts = adjustShiftsToWeek(gigTemplate, new Date());
      setFormData({ ...gigTemplate, shifts: newShifts });
      setOfferRate(gigTemplate.maxRate);
      setSelectedTemplate(gigTemplate);
    }
  };

  const onTemplateDeselected = () => {
    if (selectedTemplate) {
      setFormData({
        role: gigData?.role || "",
        description: gigData?.description || "",
        minRate: gigData?.minRate || 120,
        maxRate: gigData?.maxRate || 150,
        amountOfWorkers: gigData?.amountOfWorkers || 1,
        type: gigData?.type || GigType.PUBLIC,
        paymentType: getPaymentMethod(),
        skills: gigData?.skills || [],
        shifts: gigData?.shifts || [],
      });
      setSelectedTemplate(undefined);
    }
  };

  const onSaveGigAsTemplate = () => {
    if (selectedTemplate) {
      setPromptModalOpen(true);
    } else {
      createOrEditTemplate("Create");
    }
  };
  const createOrEditTemplate = (type: "Create" | "Edit") => {
    if (!companyState.company || apiState.current.loading) return;

    setIsApiLoading(true);
    apiState.current.loading = true;
    const id =
      type === "Edit" && selectedTemplate ? selectedTemplate.id : undefined;

    Api()
      .company.template.saveTemplate(
        companyState.company.id,
        { ...formData, shifts: removeShiftIfDeleteEntry(formData.shifts) },
        id
      )
      .then((res) => {
        if (type === "Edit") {
          const newTemplates = templates.filter(
            (template) => template.id !== selectedTemplate?.id
          );
          setTemplates([...newTemplates, { ...res.data }]);
          setSelectedTemplate(res.data);
        } else {
          setTemplates([...templates, { ...res.data }]);
        }
      })
      .finally(() => {
        setIsApiLoading(false);
        apiState.current.loading = false;
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "checkmark",
            title: t("GigsCompany.TemplateSaved"),
            message: t("GigsCompany.TemplateHasBeenSaved"),
          },
        });
      })
      .catch((err) => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "cross",
            title: t("Alerts.OhNo"),
            message: t("Alerts.SomethingWentWrong"),
          },
        });
      });

    setPromptModalOpen(false);
  };

  const onRemoveGigTemplate = (vm: ListViewModel) => {
    if (!companyState.company || apiState.current.loading) return;

    setIsApiLoading(true);
    apiState.current.loading = true;

    Api()
      .company.template.deleteTemplate(companyState.company.id, +vm.value)
      .then(() => {
        setTemplates(templates.filter((t) => t.id !== vm.value));
        setSelectedTemplate(undefined);
      })
      .finally(() => {
        setIsApiLoading(false);
        apiState.current.loading = false;
      });
  };

  const addTimeReportForShift = () => {
    if (gigData && !isClone) {
      let newShifts = formData.shifts.map((shift) => {
        if (!shift.id) {
          return { ...shift, addTimeReport: true };
        } else {
          return shift;
        }
      });
      return newShifts;
    } else {
      return formData.shifts;
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      const data: CompanyGigRequestDto = {
        ...formData,
        shifts: addTimeReportForShift(),
        id: isClone ? undefined : gigData?.id,
        location: companyState.company?.location || "59.3293, 18.0686",
        parentGigId: isClone && connectWithParentGig ? gigData?.id : undefined,
      };

      if (companyState.company) {
        setIsLoading(true);
        Api()
          .company.gig.saveGig(
            companyState.company.id,
            data,
            !isClone ? gigData?.id : undefined
          )
          .then(async (res) => {
            setIsLoading(false);
            if (onCreateSuccess) {
              onCreateSuccess(res.data.id);
            }
            if (setShowCreateGigModal) {
              setShowCreateGigModal(false);
            }
            if (gigData && !isClone) {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                  title: t("Alerts.Updated"),
                  message: t("GigsCompany.GigHasBeenUpdated"),
                },
              });
            }

            if (offerList.length > 0 && formData.type !== GigType.STAFFING) {
              let newApplications: ApplicationDto[] = [];
              setIsLoading(true);
              await Promise.all(
                offerList.map(async (offer, index) => {
                  let offerGigRequest: OfferGigRequest = {
                    companyId: res.data.companyId,
                    workerId: offer.workerId,
                    gigId: res.data.id,
                    rate:
                      offer.usingOfferRate || useOfferRateForAll
                        ? offerRate
                        : offer.rate,
                  };
                  const applicationRes = await sendOffer(
                    offerGigRequest,
                    offer.workerId
                  );
                  if (applicationRes) {
                    newApplications.push(applicationRes.data);
                  }
                  if (index + 1 === offerList.length) {
                    setIsLoading(false);
                  }
                  if (
                    index + 1 === offerList.length &&
                    offersError.length === 0
                  ) {
                    setOfferModalOpen(false);
                    alertDispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                        title: t("GigsCompany.OfferSent"),
                        message: t("GigsCompany.OfferSentToApplicant"),
                      },
                    });
                    setOfferList([]);
                  } else if (
                    index + 1 === offerList.length &&
                    offersError.length > 0
                  ) {
                    alertDispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        icon: "cross", // TODO: ADD SAME ICON AS IN APP
                        title: t("Alerts.OhNo"),
                        message: t("Alerts.SomethingWentWrong"),
                      },
                    });
                    setOfferList([]);
                    setOffersError([]);
                    setOfferModalOpen(false);
                  }
                })
              );
              applicationDispatch({
                type: "GET_ALL_APPLICATIONS",
                payload: applicationState.applications.concat(newApplications),
              });
            } else {
              setOfferList([]);
            }
            if (setShowCreateGigModal) {
              calendarActions.updateWeeklyCalendarContext(
                t,
                companyState,
                "UPDATE_OVERVIEW_WEEK_CALENDAR_V2",
                filter
              );
              setShowCreateGigModal(false);
            }
            if (fromCalendar && setShowCreateGigModal) {
              calendarActions.updateWeeklyCalendarContext(
                t,
                companyState,
                "UPDATE_WEEKLY_CALENDAR_V2",
                filter
              );
              setShowCreateGigModal(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setSavedGig(false);
            if (gigData) {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "alert", // TODO: ADD SAME ICON AS IN APP
                  title: t("Alerts.OhNo"),
                  message: t("GigsCompany.GigCouldNotBeUpdated"),
                },
              });
            } else {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "alert", // TODO: ADD SAME ICON AS IN APP
                  title: t("Alerts.OhNo"),
                  message: t("GigsCompany.GigCouldNotBeCreated"),
                },
              });
            }
          });
      }
    }
  };

  const checkShiftDates = () => {
    const now = new Date();
    return shifts.find((shift) => new Date(shift.startTime) > now);
  };

  const removeSkill = (skill: CompanyGigSkillRequestDto) => {
    if (skill.hasOwnProperty("deleteEntry")) {
      const newSkills = skills.map((element) => {
        return element === skill ? { ...element, deleteEntry: true } : element;
      });

      setFormData({ ...formData, skills: newSkills });
    } else {
      setFormData({
        ...formData,
        skills: skills.filter((element) => element !== skill),
      });
    }
  };

  const addSkill = (value: string) => {
    const duplicate = skills.find((element) => element.skill === value);

    if (
      duplicate &&
      duplicate.hasOwnProperty("deleteEntry") &&
      duplicate.deleteEntry === true
    ) {
      const newSkills = skills.map((element) =>
        element === duplicate ? { ...element, deleteEntry: false } : element
      );

      setFormData({ ...formData, skills: newSkills });
    } else {
      setFormData({
        ...formData,
        skills: [...formData.skills, { skill: value, experience: 0 }],
      });
    }

    setSkillText("");
  };

  const validateShifts = (shifts: ShiftRequestDto[]) => {
    return shifts.some(
      (shift) =>
        (shift.hasOwnProperty("deleteEntry") && shift.deleteEntry === false) ||
        !shift.hasOwnProperty("deleteEntry")
    );
  };

  const validate = () => {
    if (
      role &&
      role.length <= 24 &&
      validateShifts(shifts) &&
      maxRate &&
      minRate &&
      maxRate >= minRate &&
      description.length <= 2056 &&
      (selectedUnit || isClone || gigData)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (entities) {
      entities.forEach((entity) => {
        entity.units.forEach((unit) => {
          setEntitiesWithUnits((prevList) => [
            ...prevList,
            { key: `${unit.name}`, value: unit.id },
          ]);
        });
      });
    }
  }, [entities]);

  useEffect(() => {
    if (gigData && gigData.companyUnitId) {
      const matchingUnit = entitiesWithUnits.find(
        (unit) => unit.value === gigData.companyUnitId
      );

      if (matchingUnit) {
        setSelectedUnit(matchingUnit);
        setFormData((prevData) => ({
          ...prevData,
          companyUnitId: matchingUnit.value,
        }));
      }
    }
    if (entitiesWithUnits.length == 1) {
      setSelectedUnit(entitiesWithUnits[0]);
      setFormData({ ...formData, companyUnitId: entitiesWithUnits[0].value });
    }
  }, [entitiesWithUnits, gigData]);

  return (
    <>
      <Prompt
        when={changesMade && !savedGig}
        message={t("ProfileCompany.YouHaveUnSavedChanges")}
      />
      <LoadingWrapper loading={isLoading}>
        {!gigData && (
          <>
            <LabelStyled>{t("GigsCompany.GigTemplate")}</LabelStyled>
            <TemplateWrapper>
              {selectedTemplate && (
                <CustomIcon
                  name="cross"
                  size="30px"
                  color={Color.Destructive}
                  onClick={() => {
                    onTemplateDeselected();
                  }}
                />
              )}

              {templates?.length > 0 ? (
                <Dropdown
                  placeholder={t("GigsCompany.SelectTemplate")}
                  activeValue={selectedTemplate?.id}
                  items={templates.map((template) => ({
                    value: template.id,
                    label: template.role,
                  }))}
                  disabled={isApiLoading}
                  showSpinner={isApiLoading}
                  maxHeight={200}
                  onChange={onTemplateSelected}
                  onRemove={onRemoveGigTemplate}
                  onScrollBottom={fetchGigTemplates}
                />
              ) : isApiLoading ? (
                <StyledProgress size={18} color="inherit" />
              ) : (
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Standard}
                  style={{ marginLeft: 6 }}
                >
                  {t("GigsCompany.GigTemplateEmpty")}
                </Text>
              )}
            </TemplateWrapper>
          </>
        )}
        <InputContainer>
          <LabelStyled>
            {t("GigsCompany.RoleWanted")}
            {"*"}
          </LabelStyled>
          <RoleWrapper>
            <AutoCompleteInput
              inputValue={role}
              onChange={(val: string) =>
                setFormData({ ...formData, role: val })
              }
              selections={Roles.map((mockedRole) =>
                t(mockedRole, { ns: "roles" })
              )}
            />
            <WorkerCounter
              value={amountOfWorkers}
              onChange={(val) =>
                setFormData({ ...formData, amountOfWorkers: val })
              }
            />
          </RoleWrapper>
          {characterCounter(24, role)}
        </InputContainer>
        {((entitiesWithUnits.length > 1 && !gigData) || 
  ((gigData && selectedUnit) || (isClone && selectedUnit))) && (
            <>
              <LabelStyled>
                {t("GigsCompany.Unit")}
                {"*"}
              </LabelStyled>
              <DropDownContainer style={{ marginBottom: 10 }}>
                <Dropdown
                  disabled={
                    entitiesWithUnits.length == 1 || gigData !== undefined
                  }
                  textColor={Color.MidnightBlue}
                  smallerFont
                  placeholder={t("WorkGroup.Unit")}
                  activeValue={
                    entitiesWithUnits.length == 1
                      ? entitiesWithUnits[0].key
                      : selectedUnit?.key
                      ? `${selectedUnit.key}`
                      : undefined
                  }
                  items={entitiesWithUnits.map((unit) => ({
                    value: unit.key,
                    label: unit.key,
                    id: unit.value,
                  }))}
                  maxHeight={200}
                  onChange={(e: any) => {
                    setSelectedUnit(e);
                    setFormData({ ...formData, companyUnitId: e.id });
                  }}
                />
              </DropDownContainer>
            </>
          )}
        {recruit && (
          <InputContainer>
            <LabelStyled>{t("GigsCompany.SkillsWanted")}</LabelStyled>
            <SkillInputWrapper>
              <AutoCompleteInput
                inputValue={skillText}
                onChange={(val: string) => setSkillText(val)}
                submitValue={(value) => {
                  if (value.length <= 24) {
                    addSkill(value);
                  }
                }}
                submittedValues={skills
                  .filter((skill) => !skill.deleteEntry)
                  .map((element) => element.skill)}
                selections={Skills.map((mockedSkill) =>
                  t(mockedSkill, { ns: "skills" })
                )}
              />
              <ButtonStyled
                disabled={!skillText || skillText.length > 24}
                onClick={() => {
                  addSkill(skillText);
                }}
              >
                {t("General.Add")}
              </ButtonStyled>
            </SkillInputWrapper>
            {characterCounter(24, skillText)}
            {skills.length !== 0 && (
              <SkillsWrapper>
                {skills
                  .filter((skill) => !skill.deleteEntry)
                  .map((skill, index) => {
                    return (
                      <SkillTag key={index}>
                        <Text
                          color={Color.White}
                          fontFamily={FontFamily.MontserratSemiBold}
                          fontSize={FontSize.Standard}
                          style={{ margin: 0 }}
                        >
                          {t(skill.skill, { ns: "skills" })}
                        </Text>
                        <CustomIcon
                          name="cross"
                          size="16px"
                          color={Color.White}
                          onClick={() => {
                            removeSkill(skill);
                          }}
                        />
                      </SkillTag>
                    );
                  })}
              </SkillsWrapper>
            )}
          </InputContainer>
        )}

        <InputContainer>
          <LabelStyled>
            {`${t("GigsCompany.Shifts")} ( ${
              shifts.length > 0
                ? getWorkHours(t, ...shifts)
                : "0" +
                  t("General.HourShortened") +
                  " 0" +
                  t("General.MinutesShortened")
            })`}
          </LabelStyled>
          <Shifts
            shifts={shifts}
            onChange={(shifts) => setFormData({ ...formData, shifts })}
            isClone={isClone}
            createGig={gigData ? false : true}
          />
          <Select
            style={{ height: 48 }}
            onClick={() => {
              setShiftFormOpen(!shiftFormOpen);
            }}
          >
            <CustomIcon
              name="plus_old"
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
            />
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
              color={Color.BurntSienna}
            >
              {t("GigsCompany.AddShift")}
            </Text>
          </Select>
          <ShiftForm
            shifts={shifts}
            onChange={(shifts) => setFormData({ ...formData, shifts })}
            shiftFormOpen={shiftFormOpen}
            setShiftFormOpen={setShiftFormOpen}
            shiftDate={shiftDate}
            editGig={isClone || gigData ? true : false}
          />
          <Select
            style={{ height: 48 }}
            onClick={() => {
              setMultipleShiftsFormOpen(!multipleShiftsFormOpen);
            }}
          >
            <CustomIcon
              name="plus_old"
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
            />
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
              color={Color.BurntSienna}
            >
              {t("GigsCompany.AddSeveralShifts")}
            </Text>
          </Select>
          <MultipleShiftsForm
            shifts={shifts}
            onChange={(shifts) => setFormData({ ...formData, shifts })}
            multipleShiftsFormOpen={multipleShiftsFormOpen}
            setMultipleShiftsFormOpen={setMultipleShiftsFormOpen}
            editGig={isClone || gigData ? true : false}
          />
        </InputContainer>
        <HourlyRate
          maxHourlyRate={maxRate}
          minHourlyRate={minRate}
          onChange={(min, max) =>
            setFormData({ ...formData, minRate: min, maxRate: max })
          }
          hours={getShiftHours(...shifts)}
          shifts={shifts}
          gigType={type}
          paymentType={formData.paymentType}
        />
        <InputContainer>
          <LabelStyled>{t("GigsCompany.TypeOfGig")}</LabelStyled>

          <TypeRow>
            {gigData && !isClone ? (
              <TypeCell selected={true}>
                <Text
                  color={Color.MidnightBlue}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {formData.type == GigType.WORKPOOL
                    ? t(`GigsCompany.WorkGroup`)
                    : formData.type === GigType.HIDDEN
                    ? t(`GigsCompany.Favorites`)
                    : t(`GigsCompany.${formData.type}`)}
                </Text>
              </TypeCell>
            ) : (
              <>
                {!recruit && (
                  <>
                    <TypeCell
                      selected={formData.type === GigType.HIDDEN}
                      onClick={() =>
                        setFormData({ ...formData, type: GigType.HIDDEN })
                      }
                    >
                      <StyledIcon
                        name="favorites-active"
                        size="28px"
                        color={
                          formData.type === GigType.HIDDEN
                            ? Color.MidnightBlue
                            : Color.SeaBlue500
                        }
                      />
                      <Text
                        color={
                          formData.type === GigType.HIDDEN
                            ? Color.MidnightBlue
                            : Color.SeaBlue500
                        }
                        fontFamily={FontFamily.MontserratSemiBold}
                      >
                        {t(`GigsCompany.Favorites`)}
                      </Text>
                    </TypeCell>

                    <TypeCell
                      selected={formData.type === GigType.WORKPOOL}
                      onClick={() =>
                        setFormData({ ...formData, type: GigType.WORKPOOL })
                      }
                    >
                      <StyledIcon
                        name="group"
                        size="28px"
                        color={
                          formData.type === GigType.WORKPOOL
                            ? Color.MidnightBlue
                            : Color.SeaBlue500
                        }
                      />
                      <Text
                        color={
                          formData.type === GigType.WORKPOOL
                            ? Color.MidnightBlue
                            : Color.SeaBlue500
                        }
                        fontFamily={FontFamily.MontserratSemiBold}
                      >
                        {t(`GigsCompany.${GigType.WORKGROUP}`)}
                      </Text>
                    </TypeCell>
                  </>
                )}
                <TypeCell
                  selected={formData.type === GigType.PUBLIC}
                  onClick={() =>
                    setFormData({ ...formData, type: GigType.PUBLIC })
                  }
                >
                  <StyledIcon
                    name="eye"
                    size="28px"
                    color={
                      formData.type === GigType.PUBLIC
                        ? Color.MidnightBlue
                        : Color.SeaBlue500
                    }
                  />

                  <Text
                    color={
                      formData.type === GigType.PUBLIC
                        ? Color.MidnightBlue
                        : Color.SeaBlue500
                    }
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t(`GigsCompany.${GigType.PUBLIC}`)}
                  </Text>
                </TypeCell>
                {staffingPools && staffingPools.length > 0 && (
                  <TypeCell
                    selected={formData.type === GigType.STAFFING}
                    onClick={() =>
                      setFormData({ ...formData, type: GigType.STAFFING })
                    }
                  >
                    <StyledIcon
                      name="lock"
                      size="28px"
                      color={
                        formData.type === GigType.STAFFING
                          ? Color.MidnightBlue
                          : Color.SeaBlue500
                      }
                    />
                    <Text
                      color={
                        formData.type === GigType.STAFFING
                          ? Color.MidnightBlue
                          : Color.SeaBlue500
                      }
                      fontFamily={FontFamily.MontserratSemiBold}
                    >
                      {t(`GigsCompany.${GigType.STAFFING}`)}
                    </Text>
                  </TypeCell>
                )}
              </>
            )}
          </TypeRow>
          <TypeRow>
            <Text fontSize={FontSize.Small}>
              {formData.type == GigType.HIDDEN
                ? t(`GigsCompany.InfoFavorites`)
                : formData.type == GigType.WORKPOOL
                ? t(`GigsCompany.InfoWorkGroup`)
                : formData.type == GigType.PUBLIC
                ? t(`GigsCompany.InfoPublic`)
                : t(`GigsCompany.InfoStaffing`)}
            </Text>
          </TypeRow>
          <RowWrapper style={{ marginBottom: 20 }}>
            {/*           <Dropdown
            items={gigTypes}
            activeValue={type}
            disabledItems={
              companyState.company &&
              companyState.company?.staffingPools?.length === 0
                ? [gigTypes[3]]
                : []
            }
            onChange={(vm: ListViewModel) =>
              setFormData({ ...formData, type: vm.value as GigType })
            }
          /> */}
            {type === GigType.STAFFING &&
              staffingPools &&
              staffingPools.length > 0 && (
                <Dropdown
                  items={staffingPools.map((pool) => ({
                    value: pool.id,
                    label: pool.name,
                  }))}
                  activeValue={formData.staffingPoolId}
                  onChange={(vm: ListViewModel) =>
                    setFormData({
                      ...formData,
                      workGroupId: vm.value as number,
                    })
                  }
                />
              )}
          </RowWrapper>
          {type === GigType.WORKPOOL && (
            <>
              <LabelStyled>{t("GigsCompany.WorkGroup")}</LabelStyled>
              <DropDownContainer>
                <Dropdown
                  disabled={gigData !== undefined}
                  textColor={Color.MidnightBlue}
                  smallerFont
                  placeholder={t("WorkGroup.WorkGroup")}
                  activeValue={
                    selectedWorkGroup ? selectedWorkGroup : undefined
                  }
                  items={state.workgroups
                    .filter((m: any) => m.name !== "Favorites")
                    .map((m: any) => ({
                      value: m.id,
                      label: m.name,
                      id: m.id,
                      workers: m.value,
                    }))}
                  maxHeight={200}
                  onChange={(e: any) => {
                    setSelectedWorkGroup(e.id);
                    setFormData({
                      ...formData,
                      workGroupId: e.id as number,
                    });
                  }}
                />
              </DropDownContainer>
            </>
          )}
          <LabelStyled>{t("GigsCompany.PaymentMethod")}</LabelStyled>
          {!gigData || isClone ? (
            <TypeRow style={{ justifyContent: "space-around" }}>
              <TypeCell
                selected={formData.paymentType === PaymentMethod.SIMFLEXITY_PAY}
                onClick={() =>
                  setFormData({
                    ...formData,
                    paymentType: PaymentMethod.SIMFLEXITY_PAY,
                  })
                }
              >
                <Text
                  fontSize={FontSize.Small}
                  color={
                    formData.paymentType === PaymentMethod.SIMFLEXITY_PAY
                      ? Color.MidnightBlue
                      : Color.SeaBlue500
                  }
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("SimflexityPay.SimflexityPay")}
                </Text>
              </TypeCell>
              <TypeCell
                selected={formData.paymentType === PaymentMethod.NONE}
                onClick={() =>
                  setFormData({
                    ...formData,
                    paymentType: PaymentMethod.NONE,
                  })
                }
              >
                <Text
                  fontSize={FontSize.Small}
                  color={
                    formData.paymentType === PaymentMethod.NONE
                      ? Color.MidnightBlue
                      : Color.SeaBlue500
                  }
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("GigsCompany.OwnSalaryProcessing")}
                </Text>
              </TypeCell>
            </TypeRow>
          ) : (
            <TypeCell selected={true}>
              <Text
                fontSize={FontSize.Small}
                color={Color.MidnightBlue}
                fontFamily={FontFamily.MontserratSemiBold}
              >
                {formData.paymentType === PaymentMethod.NONE
                  ? t("GigsCompany.OwnSalaryProcessing")
                  : t("SimflexityPay.SimflexityPay")}
              </Text>
            </TypeCell>
          )}
        </InputContainer>
        {(!gigData || isClone) && formData.type !== GigType.PUBLIC && (
          <InputContainer style={{ width: "300px", marginBottom: "30px" }}>
            <ButtonStyled
              disabled={formData.type == GigType.WORKPOOL && !selectedWorkGroup}
              onClick={() => setOfferModalOpen(true)}
              backgroundColor={Color.MidnightBlue}
              fullWidth
            >
              {`${t("GigsCompany.OfferGig")} (${offerList.length})`}
            </ButtonStyled>
          </InputContainer>
        )}
        <InputContainer>
          <LabelStyled>{t("GigsCompany.GigDescription")}</LabelStyled>
          <TextArea
            style={{ height: 20 }}
            ref={textAreaEl}
            name="description"
            placeholder={t("GigsCompany.EnterDescriptionHere")}
            defaultValue={description}
            onChange={(e) => {
              setFormData({
                ...formData,
                description: e.currentTarget.value,
              });
            }}
          ></TextArea>
          {characterCounter(2056, description, true)}
        </InputContainer>
        {isClone && (
          <CheckBoxContainer>
            <Checkbox
              checked={connectWithParentGig}
              name="connectWithParentGig"
              onChange={(e: any) => {
                setConnectWithParentGig(e.target.checked);
              }}
            />
            <CheckboxText>{t("GigsCompany.ConnectToParentGig")}</CheckboxText>
          </CheckBoxContainer>
        )}
        <LinkWrapper>
          <ButtonStyled
            icon={isLoading}
            disabled={!validate()}
            onClick={() => {
              if (checkShiftDates()) {
                setSavedGig(true);
                handleSubmit();
              } else {
                setOldShiftModalOpen(true);
              }
            }}
          >
            {isLoading ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              t(
                gigData && !isClone
                  ? "GigsCompany.UpdateGig"
                  : "GigsCompany.CreateGig"
              )
            )}
          </ButtonStyled>
          <ButtonStyled
            icon={isApiLoading}
            disabled={!validate()}
            backgroundColor={Color.MidnightBlue}
            onClick={onSaveGigAsTemplate}
          >
            {isApiLoading ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              t("GigsCompany.CreateGigTemplate")
            )}
          </ButtonStyled>
          <CustomLink
            color={Color.Destructive}
            onClick={() => {
              if (setShowCreateGigModal) {
                setShowCreateGigModal(false);
                return;
              }

              history.goBack();
            }}
          >
            {t("GigsCompany.Discard")}
          </CustomLink>
        </LinkWrapper>
        <Modal
          open={offerModalOpen}
          onClose={() => {
            setOfferModalOpen(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <OfferModal
            offerList={offerList}
            setOfferList={setOfferList}
            createGig={true}
            gigData={gigData}
            setOfferModalOpen={setOfferModalOpen}
            offerRate={offerRate}
            setOfferRate={setOfferRate}
            useOfferRateForAll={useOfferRateForAll}
            setUseOfferRateForAll={setUseOfferRateForAll}
            selectedWorkGroup={
              formData.type == GigType.WORKPOOL ? selectedWorkGroup : null
            }
            type={formData.type}
            filter={filter}
            amountOfWorkers={formData.amountOfWorkers}
          />
        </Modal>
        <Modal
          open={isPromptModalOpen}
          onClose={() => setPromptModalOpen(false)}
        >
          <PromptModal
            header={t("GigsCompany.GigTemplateAlert")}
            message={t("GigsCompany.GigTemplateAlertBody")}
            accept={{
              label: t("GigsCompany.GigTemplateSave"),
              color: Color.MidnightBlue,
              callback: () => {
                createOrEditTemplate("Create");
              },
            }}
            alternativeAccept={{
              label: t("GigsCompany.GigTemplateOverwrite"),
              color: Color.Destructive,
              callback: () => {
                createOrEditTemplate("Edit");
              },
            }}
            cancel={{
              label: t("General.Cancel"),
              callback: () => {
                setPromptModalOpen(false);
              },
            }}
          />
        </Modal>
        <Modal
          open={oldShiftModalOpen}
          onClose={() => setOldShiftModalOpen(false)}
        >
          <PromptModal
            header={t("GigsCompany.OneOrMoreOfYourShiftsHavePassed")}
            message={t("GigsCompany.DoYouWishToContinueAnyway")}
            accept={{
              label: t("General.Continue"),
              color: Color.MidnightBlue,
              callback: () => {
                setSavedGig(true);
                handleSubmit();
              },
            }}
            cancel={{
              label: t("General.Cancel"),
              color: Color.Destructive,
              callback: () => {
                setOldShiftModalOpen(false);
              },
            }}
          />
        </Modal>
      </LoadingWrapper>
    </>
  );
};
