import React, { useEffect, useState } from "react";
import { Modal, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { GigDetail } from "web-apps/company/components/gigDetail/gigDetail.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useGetGig } from "hooks/useGetGig";
import { Color } from "config/colors";
import { Text, H3 } from "components/Typography/text.styled";
import {
  SummaryModalBody,
  RowWrapper,
} from "web-apps/company/components/gigDetail/gigDetail.styled";
import { FontSize } from "config/font";
import { LinkWrapper } from "../gigApplicants/gigContract.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { PaymentMethod, GigType } from "model/Gig";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { Logo } from "components/nav/logo/logo.styled";
import { TagsContainer } from "web-apps/company/components/gigDetail/gigDetail.styled";
import { Instant, LocalDateTime } from "@js-joda/core";

type GigSummaryModalProps = {
  gigId: number | null;
  isOpen: boolean;
  onClose: () => void;
};

export const GigSummaryModal: React.FC<GigSummaryModalProps> = ({
  gigId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [gigData, isLoading, , fetchGig] = useGetGig(gigId ?? "");
  const [startTimeGig, setStartTimeGig] = useState<LocalDateTime>();
  const [endTimeGig, setEndTimeGig] = useState<LocalDateTime>();
  const startTime = gigData && (gigData.shifts?.[0]?.startTime ?? "");
  const endTime =
    gigData && (gigData.shifts?.[gigData.shifts.length - 1]?.endTime ?? "");
  const now = LocalDateTime.now();

  useEffect(() => {
    if (gigId && !gigData) {
      fetchGig(gigId);
    }
  }, [gigId, gigData, fetchGig]);

  useEffect(() => {
    if (gigData && companyState.company?.id) {
      if (gigData.companyId !== companyState.company?.id) {
        onClose();
      }
    }
  }, [gigData, companyState.company?.id, onClose]);

  useEffect(() => {
    if (gigData?.shifts?.[0] && gigData?.shifts?.[gigData.shifts.length - 1]) {
      setStartTimeGig(
        LocalDateTime.ofInstant(Instant.parse(gigData.shifts[0].startTime))
      );
      setEndTimeGig(
        LocalDateTime.ofInstant(
          Instant.parse(gigData.shifts[gigData.shifts.length - 1].endTime)
        )
      );
    } else {
      setStartTimeGig(undefined);
      setEndTimeGig(undefined);
    }
  }, [gigData]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="gig-summary-modal"
      aria-describedby="gig-summary-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <SummaryModalBody>
        <div>
          {isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            gigData && (
              <div>
                <div>
                  <LinkWrapper
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginBottom: "50px",
                    }}
                  >
                    <TagsContainer>
                      {startTime &&
                        endTime &&
                        Date.now() > Date.parse(startTime) &&
                        Date.now() < Date.parse(endTime) && (
                          <Text
                            fontSize={FontSize.Small}
                            color={Color.BurntSienna}
                            backgroundColor={Color.Positive}
                            padding
                            style={{
                              margin: 0,
                              lineHeight: FontSize.Small,
                              width: 70,
                              marginBottom: 10,
                            }}
                          >
                            {t("GigsCompany.Ongoing")}
                          </Text>
                        )}
                      {startTimeGig &&
                        now > startTimeGig &&
                        endTimeGig &&
                        now > endTimeGig && (
                          <Text
                            fontSize={FontSize.Small}
                            color={Color.Destructive}
                            backgroundColor={Color.LightWarning}
                            padding
                            style={{
                              margin: 0,
                              lineHeight: FontSize.Small,
                              width: 70,
                              marginBottom: 10,
                              textAlign: "center",
                            }}
                          >
                            {t("GigsCompany.Passed")}
                          </Text>
                        )}
                      <Text
                        fontSize={FontSize.Small}
                        color={Color.DarkGrey}
                        backgroundColor={Color.Disabled}
                        padding
                        style={{
                          margin: 0,
                          lineHeight: FontSize.Small,
                          width:
                            gigData?.type == GigType.STAFFING ||
                            gigData?.type == GigType.WORKPOOL
                              ? 80
                              : 60,
                          marginBottom: 10,
                          textAlign: "center",
                          marginLeft: 10,
                        }}
                      >
                        {gigData?.type == GigType.PUBLIC
                          ? t("GigsCompany.Public")
                          : gigData?.type == GigType.HIDDEN
                          ? t("GigsCompany.Favorites")
                          : gigData?.type == GigType.STAFFING
                          ? t("GigsCompany.Staffing")
                          : t("GigsCompany.Workpool")}
                      </Text>
                      {gigData?.paymentType == PaymentMethod.SIMFLEXITY_PAY && (
                        <Logo
                          src={SimflexityPayLogo}
                          style={{
                            height: "18px",
                            width: "auto",
                            marginLeft: 10,
                            marginTop: 2,
                          }}
                          alt="logo"
                        />
                      )}
                    </TagsContainer>
                    <CustomIcon
                      color={Color.LighterDestructive}
                      onClick={onClose}
                      name={"cross"}
                    >
                      {t("General.Close")}
                    </CustomIcon>
                  </LinkWrapper>
                </div>
                <RowWrapper justify="space-between">
                  <H3 style={{ margin: 0 }}>
                    {t("GigsCompany.GigAt", {
                      position: t(gigData.role || "", { ns: "roles" }),
                      company: companyState.company?.companyName,
                    })}
                  </H3>
                </RowWrapper>
                <GigDetail
                  gigData={gigData}
                  onGigClosed={() => fetchGig(gigData.id)}
                  isModalOpen={true}
                />
              </div>
            )
          )}
        </div>
      </SummaryModalBody>
    </Modal>
  );
};
