import { CircularProgress, Modal } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CompanyStructureDto, NewWorker, Unit } from "model/CompanyStructure";
import { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Api, handleApiCall } from "services/api/api.service";
import { TextInput } from "../../../gig/gigs.styled";
import { RemoveModal } from "../companyStructureRemoveModal.component";
import {
  findAllWorkers,
  findUnitById,
  isNewWorkerValid,
} from "../companyStructureUtils";
import {
  CloseButtonContainer,
  DisabledLinkTextContainer,
  EditUnitButtonContainer,
  EditUnitModalBody,
  LinkTextContainer,
  LoadingContainer,
  Row,
  Spacer,
  UnitContainer,
} from "./companyStructureEditUnit.styled";
import ChooseExistingWorkersModal from "./companyStructureEditUnitChooseExisting.component";
import WorkerTableComponent from "./companyStructureEditUnitWorkerTable.component";

interface WorkerModalProps {
  companyId: number;
  entityId: number;
  unitInit: Unit | null;
  onClose: (structureUpdated: boolean) => void;
}

export const EditUnit: React.FC<WorkerModalProps> = ({
  companyId,
  entityId,
  unitInit,
  onClose,
}) => {
  const { t } = useTranslation();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [chooseExistingModalOpen, setChooseExistingModalOpen] = useState(false);

  const [newWorker, setNewWorker] = useState<NewWorker>({
    firstName: "",
    lastName: "",
    personalIdentityNumber: "",
    email: "",
    phoneNumber: "+46",
  });

  const [unit, setUnit] = useState<Unit | null>(unitInit);
  const [unitName, setUnitName] = useState<string | undefined>(unitInit?.name);

  const handleErrorMessage = (message: string) =>
    setErrorMessage(getErrorMessage(t, message));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");

    const { name, value } = e.target;
    setNewWorker({ ...newWorker, [name]: value });
  };

  const newWorkerIsValid: boolean = isNewWorkerValid(newWorker);

  const [isLoading, setIsLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  const editingExistingUnit = unitInit || unit;

  const [structureHasUpdated, setStructureHasUpdated] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const [triggerRefresh, setTriggerFetchRefresh] = useState(0);

  const incrementTriggerFetch = () => {
    setTriggerFetchRefresh((prevValue) => prevValue + 1);
    setStructureHasUpdated(true);
  };

  const [workersCount, setWorkersCount] = useState(0);

  const fetchCompanyStructureData = () => {
    setIsLoading(true);

    handleApiCall({
      apiCall: () => Api().company.fetchCompanyStructure(companyId),
      onResult: (res) => {
        const structure = res.data as CompanyStructureDto;

        setWorkersCount(findAllWorkers(structure).length);

        const unitId = unitInit ? unitInit?.id : unit?.id;

        if (unitId) {
          const updatedUnit = findUnitById(structure, unitId);

          updatedUnit && setUnit(updatedUnit);
        }
      },
      onError: (err) => {
        console.log("Error fetching company structure", err);
      },
      onCompleted: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchCompanyStructureData();
  }, [triggerRefresh]);

  const addNewWorker = () => {
    if (unit) {
      setIsLoading(true);

      handleApiCall({
        apiCall: () =>
          Api().company.addNewWorkers(companyId, unit.id, [newWorker]),
        onResult: (res) => {
          if (res.data?.errors?.length === 0) {
            setNewWorker({
              firstName: "",
              lastName: "",
              personalIdentityNumber: "",
              email: "",
              phoneNumber: "+46",
            });
          } else {
            handleErrorMessage(res.data?.errors[0].errorMessage);
          }

          incrementTriggerFetch();
        },
        onError: (err) => {
          console.log("Error adding worker", err);
          handleErrorMessage(err.response.data.errorMessage);
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      });
    }
  };

  const deleteWorker = (workerId: number) => {
    if (unit) {
      setIsLoading(true);

      handleApiCall({
        apiCall: () =>
          Api().company.deleteWorker(companyId, entityId, unit.id, workerId),
        onResult: (res) => {
          incrementTriggerFetch();
        },
        onError: (err) => {
          console.log("Error deleting worker", err);
          handleErrorMessage(err.response.data.errorMessage);
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      });
    }
  };

  const addUnit = () => {
    if (unitName) {
      setIsLoading(true);

      handleApiCall({
        apiCall: () =>
          Api().company.addCompanyStructureUnit(companyId, entityId, {
            name: unitName,
          }),
        onResult: (res) => {
          setUnit(res.data);

          incrementTriggerFetch();
          setShowSuccess(true);
        },
        onError: (err) => {
          console.log("Error adding unit", err);
          handleErrorMessage(err.response.data.errorMessage);
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      });
    }
  };

  const updateUnit = () => {
    if (unitName && unit) {
      setIsLoading(true);

      handleApiCall({
        apiCall: () =>
          Api().company.updateCompanyStructureUnit(
            companyId,
            entityId,
            unit.id,
            { name: unitName }
          ),
        onResult: (res) => {
          incrementTriggerFetch();
        },
        onError: (err) => {
          console.log("Error updating unit", JSON.stringify(err));
          handleErrorMessage(err.response.data.errorMessage);
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      });
    }
  };

  const removeUnit = () => {
    if (unitName && unit) {
      setIsLoading(true);

      handleApiCall({
        apiCall: () =>
          Api().company.deleteCompanyStructureUnit(
            companyId,
            entityId,
            unit.id
          ),
        onResult: () => {
          onClose(true);
        },
        onError: (err) => {
          console.log("Error deleting unit", JSON.stringify(err));
          handleErrorMessage(err.response.data.errorMessage);
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose(structureHasUpdated)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <EditUnitModalBody>
        <CloseButtonContainer>
          <CustomIcon
            color={Color.LighterDestructive}
            onClick={() => {
              onClose(structureHasUpdated);
            }}
            name={"cross"}
          >
            {t("General.Close")}
          </CustomIcon>
        </CloseButtonContainer>

        <Text fontFamily={FontFamily.MontserratBold} fontSize={FontSize.H4}>
          {editingExistingUnit ? t("General.Edit") : t("General.Create")}
        </Text>

        <Text
          fontFamily={FontFamily.MontserratRegular}
          fontSize={FontSize.Standard}
        >
          {t("CompanyStructure.CreateUnitInfo")}
        </Text>

        <Spacer />
        <Row>
          <UnitContainer>
            <TextInput
              name="unit"
              fontSize={FontSize.Large}
              value={unitName}
              onChange={(e) => {
                setUnitName(e.currentTarget.value);
                setErrorMessage("");
              }}
              placeholder={`${t("CompanyStructure.Unit")}`}
            />
          </UnitContainer>
          <EditUnitButtonContainer>
            <ButtonStyled
              style={{ marginLeft: 20 }}
              disabled={unitName === "" || unitName === unit?.name}
              onClick={() => {
                if (unit) {
                  updateUnit();
                } else {
                  addUnit();
                }
              }}
              backgroundColor={Color.ModernGreen}
            >
              {editingExistingUnit
                ? t("General.Save")
                : t("CompanyStructure.CreateUnit")}
            </ButtonStyled>

            <ButtonStyled
              style={{ marginLeft: 20 }}
              isCancel
              disabled={unit === null}
              onClick={() => {
                setRemoveModalOpen(true);
              }}
              backgroundColor={Color.LighterDestructive}
            >
              {t("General.Remove")}
            </ButtonStyled>

            <LoadingContainer>
              {isLoading && <CircularProgress />}
            </LoadingContainer>
          </EditUnitButtonContainer>
        </Row>
        {showSuccess && (
          <Text color={Color.ModernGreen} fontSize={FontSize.Small}>
            {t("CompanyStructure.UnitCreated")}
          </Text>
        )}
        <Spacer />

        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.MidnightBlue}
          fontSize={FontSize.Large}
        >
          {t("CompanyStructure.Workers")}
        </Text>

        <WorkerTableComponent
          unit={unit}
          newWorker={newWorker}
          newWorkerIsValid={newWorkerIsValid}
          handleInputChange={handleInputChange}
          addNewWorker={addNewWorker}
          deleteWorker={deleteWorker}
          t={t}
        />

        {!unit || workersCount === 0 ? (
          <DisabledLinkTextContainer>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.SeaBlue300}
              fontSize={FontSize.Small}
            >
              {t("CompanyStructure.ChooseExisting")}
            </Text>
            <CustomIcon name="person" size="25px" color={Color.LightGrey} />
          </DisabledLinkTextContainer>
        ) : (
          <LinkTextContainer
            onClick={() => {
              setChooseExistingModalOpen(true);
            }}
          >
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.MidnightBlue}
              fontSize={FontSize.Small}
            >
              {t("CompanyStructure.ChooseExisting")}
            </Text>
            <CustomIcon name="person" size="25px" color={Color.MidnightBlue} />
          </LinkTextContainer>
        )}

        {errorMessage && (
          <Text color={Color.Destructive} fontSize={FontSize.Small}>
            {errorMessage}
          </Text>
        )}

        {removeModalOpen && unitName && (
          <RemoveModal
            message={`${unitName} ${t(
              "CompanyStructure.AreYouSureToRemoveUnit"
            )}`}
            onCloseRemoveModal={() => setRemoveModalOpen(false)}
            onClick={removeUnit}
            t={t}
          />
        )}

        {chooseExistingModalOpen && unit && (
          <ChooseExistingWorkersModal
            companyId={companyId}
            entityId={entityId}
            unitId={unit.id}
            onClose={() => setChooseExistingModalOpen(false)}
            onStructureRefresh={() => {
              fetchCompanyStructureData();
              setStructureHasUpdated(true);
            }}
          />
        )}
      </EditUnitModalBody>
    </Modal>
  );
};

const getErrorMessage = (
  t: TFunction<"translation", undefined>,
  serverMessage: string
) => {
  let userMessage: string;

  switch (serverMessage) {
    case "":
      userMessage = "";
      break;
    case "Company Unit name is already in use":
      userMessage = t("CompanyStructure.ErrorUnitNameInUse");
      break;
    case "Worker already exists":
      userMessage = t("CompanyStructure.ErrorWorkerExists");
      break;
    case "Invalid personal identity number":
      userMessage = t("CompanyStructure.ErrorEnterValidPersonalIdentityNumber");
      break;
    default:
      userMessage = t("General.ErrorOccurred");
      break;
  }

  return userMessage;
};
