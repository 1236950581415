import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";

const inviteWorkerToWorkGroup = async (
  companyId: number,
  workGroupId: number,
  phonenumber: string,
  hourlyPay: number,
  monthlyPay: number,
  workerClassification: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers`,
    {
      phonenumber,
      hourlyPay,
      monthlyPay,
      workerClassification
    }
  );
};

const addWorkerToWorkGroup = async (
  companyId: number,
  workGroupId: number,
  workerId: number,
  hourlyPay: number,
  monthlyPay: number,
  workerClassification: string,
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers`,
    {
      workerId,
      hourlyPay,
      monthlyPay,
      workerClassification
    }
  );
};

const getWorkGroups = async (companyId: number): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workgroups`
  );
};

const createWorkGroup = async (
  companyId: number,
  name: string,
  publicSearch: boolean,
  hourlyPay: number,
  monthlyPay: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/workgroups`,
    {
      companyId,
      name,
      publicSearch,
      hourlyPay,
      monthlyPay,
    }
  );
};

const getEntities = async (companyId: number): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/structure?includeWorkers=true`
  );
};

const getEntitiesWithoutWorkers = async (
  companyId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/structure?includeWorkers=false`
  );
};

const getWorkGroupMembers = async (
  companyId: number,
  workGroupId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers?status=Accepted`
  );
};

const getInvitedToWorkGroup = async (
  companyId: number,
  workGroupId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers?status=Pending`
  );
};

const getDeclinedWorkGroup = async (
  companyId: number,
  workGroupId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers?status=Declined`
  );
};

const deleteWorkerFromWorkGroup = async (
  companyId: number,
  workGroupId: number,
  workerId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers/${workerId}`
  );
};

const deleteWorkGroup = async (
  companyId: number,
  workGroupId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}`,
    { active: false }
  );
};

const deleteInvitation = async (
  companyId: number,
  inviteId: number
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/invites/${inviteId}`,
    { status: "Withdrawn" }
  );
};

const updateWorkGroupWorker = async (
  companyId: number,
  workGroupId: number,
  workerId: number,
  setting: string,
  value: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/workers/${workerId}/settings`,
    { key: setting, value }
  );
};

const updateWorkGroup = async (
  companyId: number,
  workGroupId: number,
  setting: string,
  value: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}/settings`,
    { key: setting, value }
  );
};

const updateWorkGroupName = async (
  companyId: number,
  workGroupId: number,
  name: string
): Promise<AxiosResponse> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/workgroups/${workGroupId}`,
    { name, active: true }
  );
};

export const workgroup = {
  inviteWorkerToWorkGroup,
  addWorkerToWorkGroup,
  getWorkGroups,
  createWorkGroup,
  getEntities,
  getWorkGroupMembers,
  getInvitedToWorkGroup,
  getDeclinedWorkGroup,
  deleteWorkerFromWorkGroup,
  updateWorkGroupWorker,
  deleteWorkGroup,
  updateWorkGroup,
  deleteInvitation,
  getEntitiesWithoutWorkers,
  updateWorkGroupName,
};
