import React, { useEffect, useState } from "react";
import {
  LinkWrapper,
  WorkGroupDetailsButtonContainer,
  WorkGroupDetailsModalBody,
} from "./workgroup.styled";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { WorkGroupTabs } from "./workgroupTabs.component";
import { useTranslation } from "react-i18next";
import {
  WorkGroup,
  WorkGroupDetailsStatus,
  WorkGroupWorker,
} from "model/Workgroup";
import { Modal } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { WorkGroupAddWorkerModal } from "./workgroupAddWorkerModal.component";
import { WorkGroupInviteWorkerModal } from "./workgroupInviteWorkerModal.component";
import { WorkGroupDetailsRow } from "./workgroupDetailsRow.component";

type Props = {
  members: WorkGroupWorker[];
  declined: WorkGroupWorker[];
  invited: WorkGroupWorker[];
  showWorkGroupDetails: boolean;
  setShowWorkGroupDetails: React.Dispatch<React.SetStateAction<boolean>>;
  workgroup: WorkGroup;
};

export const WorkGroupDetails: React.FC<Props> = ({
  members,
  declined,
  invited,
  showWorkGroupDetails,
  setShowWorkGroupDetails,
  workgroup,
}) => {
  const [status, setStatus] = useState(
    members.length !== 0
      ? WorkGroupDetailsStatus.Members
      : invited.length !== 0
      ? WorkGroupDetailsStatus.Invited
      : WorkGroupDetailsStatus.Declined
  );
  const [workers, setWorkers] = useState<WorkGroupWorker[]>([]);
  const { t } = useTranslation();
  const [showInviteWorkerModal, setShowInviteWorkerModal] = useState(false);
  const [showAddWorkerModal, setShowAddWorkerModal] = useState(false);

  useEffect(() => {
    switch (status) {
      case WorkGroupDetailsStatus.Members:
        setWorkers(members);
        break;
      case WorkGroupDetailsStatus.Invited:
        setWorkers(invited);
        break;
      case WorkGroupDetailsStatus.Declined:
        setWorkers(declined);
        break;
      default:
        setWorkers(members);
    }
  }, [status, members, invited, declined]);

  return (
    <>
      <Modal
        open={showWorkGroupDetails}
        onClose={() => setShowWorkGroupDetails(false)}
      >
        <WorkGroupDetailsModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowWorkGroupDetails(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontSize={FontSize.H3}
            fontFamily={FontFamily.MontserratRegular}
            style={{ textAlign: "center", marginTop: 0 }}
          >
            {workgroup.name}
          </Text>
          <WorkGroupTabs
            setStatus={setStatus}
            status={status}
            membersLength={members.length}
            invitedLength={invited.length}
            declinedLength={declined.length}
          />
          {workers.map((worker) => {
            const hourlyPaySetting = worker.settings.find(
              (setting) => setting.key === "HourlyPay"
            );
            const monthlyPaySetting = worker.settings.find(
              (setting) => setting.key === "MonthlyPay"
            );
            const workerClassification = worker.settings.find(
              (setting) => setting.key === "WorkerClassification"
            );
            return (
              <WorkGroupDetailsRow
                hourlyPaySetting={hourlyPaySetting}
                monthlyPaySetting={monthlyPaySetting}
                workerClassification={workerClassification}
                w={worker}
                status={status}
              />
            );
          })}
          <WorkGroupDetailsButtonContainer>
            <ButtonStyled
              backgroundColor={Color.ModernGreen}
              style={{ marginRight: 10 }}
              onClick={() => setShowInviteWorkerModal(true)}
            >
              {t("WorkGroup.Invite")}
            </ButtonStyled>
            <ButtonStyled
              backgroundColor={Color.ModernGreen}
              style={{ marginRight: 10 }}
              onClick={() => setShowAddWorkerModal(true)}
            >
              {t("WorkGroup.Add")}
            </ButtonStyled>
          </WorkGroupDetailsButtonContainer>
          {showAddWorkerModal && (
            <WorkGroupAddWorkerModal
              workGroups={[workgroup]}
              setShowAddWorkerModal={setShowAddWorkerModal}
              showAddWorkerModal={showAddWorkerModal}
              fromDetails
            />
          )}
          {showInviteWorkerModal && (
            <WorkGroupInviteWorkerModal
              workGroups={[workgroup]}
              setShowInviteWorkerModal={setShowInviteWorkerModal}
              showInviteWorkerModal={showInviteWorkerModal}
              fromDetails
            />
          )}
        </WorkGroupDetailsModalBody>
      </Modal>
    </>
  );
};
