import React, { useEffect, useState } from "react";
import { Logo } from "components/nav/logo/logo.styled";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CompanyGigDto } from "model/Gig";
import { ActiveGig } from "./activeGig.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text, H4, CustomLink } from "components/Typography/text.styled";
import {
  ProfileDiv,
  ProfileGrid,
  AboutDiv,
  RowWrapper,
  DividerLine,
  CompanyImage,
  ActiveGigsContainer,
  NoGigsWrapper,
  ImageWrapper,
  FavoritesWrapper,
  SimflexityPayWrapper,
} from "./profileInfo.styled";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Api } from "services/api/api.service";
import { Modal } from "@material-ui/core";
import { ModalBody } from "../gig/gigs.styled";
import { Cell } from "../applicantDetails/applicantDetails.styled";
import { StaffingPool } from "model/Workgroup";

export const ProfileInfo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyState] = useCompanyStore();
  const [connectedSimflexityPay, setConnectedSimflexityPay] = useState(false);
  const [activeGigs, setActiveGigs] = useState<CompanyGigDto[]>([]);
  const [gigsLoading, setGigsLoading] = useState(false);
  const [activeGigsCount, setActiveGigsCount] = useState(0);
  const [showStaffingPoolModal, setShowStaffingPoolModal] = useState(false);
  const [staffingPools, setStaffingPools] = useState<StaffingPool[] | null>([]);

  useEffect(() => {
    if (companyState.company?.companySettings) {
      const found = companyState.company?.companySettings.find(
        (setting) =>
          setting.key === "SimflexityPay" &&
          setting.value?.toLowerCase() === "true"
      );
      if (found && found !== undefined) {
        setConnectedSimflexityPay(true);
      }
    }
  }, [companyState.company?.companySettings]);

  useEffect(() => {
    if (companyState.company?.id) {
      Api()
        .company.gig.getActiveGigsCount(companyState.company.id)
        .then((res) => {
          setActiveGigsCount(res.data.value);
        })
        .catch((err) => {
          console.log(err);
          setActiveGigsCount(0);
        });

        //get staffing pools
        Api()
        .company.staffingWorkerPool.getStaffingPools(companyState.company.id)
        .then((res) => {
          setStaffingPools(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [companyState.company?.id]);

  useEffect(() => {
    if (companyState.company?.id) {
      setGigsLoading(true);
      Api()
        .company.gig.getGigsWithState(
          companyState.company.id,
          "Active",
          true,
          0,
          2,
          null
        )
        .then((res) => {
          setActiveGigs(res.data.gigs);
          setGigsLoading(false);
        })
        .catch((err) => {
          console.log("Error fetching active gigs", err);
          setGigsLoading(false);
        });
    }
  }, [companyState.company?.id]);

  return (
    <ProfileGrid>
      <ProfileDiv>
        <ImageWrapper>
          {companyState.imageIsLoading ? (
            <CircularProgress />
          ) : companyState.companyImage ? (
            <CompanyImage src={companyState.companyImage} />
          ) : (
            <CustomIcon
              className="icon"
              square
              width="250px"
              name="image"
              size="190px"
              color={Color.SeaBlue500}
              backgroundColor={Color.SeaBlue300}
              padding="10px"
            />
          )}
        </ImageWrapper>

        <Text fontSize={FontSize.H4} fontFamily={FontFamily.MontserratSemiBold}>
          {companyState.company?.companyName}
        </Text>
        <Text
          color={Color.SeaBlue600}
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}
        >
          {companyState.company?.city}
        </Text>

        {/* <RowWrapper>*/}
        <div style={{ margin: "15px" }}>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratBold}
            color={Color.MidnightBlue}
          >
            {activeGigsCount}
          </Text>
          <LabelStyled fontFamily={FontFamily.MontserratRegular}>
            {t("ProfileCompany.ActiveGigs")}
          </LabelStyled>
        </div>
        {/* <div> ADD WHEN MEMBERSHIP IS IMPLEMENTED
            <Text
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratBold}
              color={Color.MidnightBlue}
            >
              {t("CompanySubscription.FreeRide")}
            </Text>
            <LabelStyled fontFamily={FontFamily.MontserratRegular}>
              {t("ProfileCompany.Membership")}
            </LabelStyled>
          </div> */}
        {/*</RowWrapper>*/}

        <FavoritesWrapper
          onClick={() => {
            history.push("profile/favorites");
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomIcon
              name="favorites-active"
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
            />
            <Text
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CompanyFavorite.Favorites")}
            </Text>
          </div>
          <div>
            <CustomIcon
              name="chevron-right"
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
            />
          </div>
        </FavoritesWrapper>
        {staffingPools &&
          staffingPools.length > 0 && (
            <FavoritesWrapper
              style={{ marginTop: -30 }}
              onClick={() => {
                setShowStaffingPoolModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomIcon
                  name="person"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
                <Text
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {t("GigsCompany.StaffingPools")}
                </Text>
              </div>
              <div>
                <CustomIcon
                  name="chevron-right"
                  size="48px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
              </div>
            </FavoritesWrapper>
          )}

        {connectedSimflexityPay && (
          <SimflexityPayWrapper>
            <Text>{t("Gigapay.ConnectedTo")}</Text>
            <Logo
              src={SimflexityPayLogo}
              style={{ height: "20px", width: "auto", marginBottom: "10px" }}
              alt="logo"
            />
          </SimflexityPayWrapper>
        )}
        <ButtonStyled
          onClick={() => {
            history.push("./settings?page=profile");
          }}
          icon={true}
        >
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.White}
            style={{ margin: "0 10px 0 0" }}
          >
            {t("ProfileCompany.EditProfile")}
          </Text>
          <CustomIcon name="settings" size="27px" color={Color.White} />
        </ButtonStyled>
      </ProfileDiv>
      <DividerLine />
      <AboutDiv>
        <LabelStyled>{t("ProfileCompany.AboutUs")}</LabelStyled>
        {companyState.company?.description ? (
          <Text fontSize={FontSize.Large} style={{ whiteSpace: "pre-line" }}>
            {companyState.company.description}
          </Text>
        ) : (
          <Text color={Color.SeaBlue300}>
            {t("ProfileCompany.NoDescription")}
          </Text>
        )}
      </AboutDiv>
      <ActiveGigsContainer>
        <RowWrapper style={{ margin: 0 }}>
          <LabelStyled>
            {t("ProfileCompany.ActiveGigs")} ({activeGigsCount})
          </LabelStyled>
          <CustomLink
            to="./gigs"
            color={Color.BurntSienna}
            fontSize={FontSize.Standard}
          >
            {t("ProfileCompany.SeeAll")}
          </CustomLink>
        </RowWrapper>
        {gigsLoading ? (
          <NoGigsWrapper>
            <CircularProgress />
          </NoGigsWrapper>
        ) : (
          <>
            {activeGigs.map((gig, index) => (
              <ActiveGig key={index} gigData={gig} />
            ))}
            {activeGigs.length === 0 && !gigsLoading && (
              <NoGigsWrapper>
                <H4>{t("GigsCompany.NoGigs")}</H4>
                <Text color={Color.SeaBlue400} padding>
                  {t("GigsCompany.NoPublishedGigs")}
                </Text>
              </NoGigsWrapper>
            )}
          </>
        )}
      </ActiveGigsContainer>
      <Modal
        open={showStaffingPoolModal}
        onClose={() => {
          setShowStaffingPoolModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody style={{ width: "500px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("GigsCompany.StaffingPools")}
            </Text>
            {staffingPools?.map((pool) => (
              <Cell style={{ marginBottom: 6 }}>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Large}
                >
                  {pool.name}
                </Text>
              </Cell>
            ))}
            {!staffingPools ||
              (staffingPools.length < 1 && (
                <Text
                  fontFamily={FontFamily.MontserratRegular}
                  fontSize={FontSize.Large}
                  color={Color.SeaBlue600}
                >
                  {t(
                    "GigsCompany.ThisCompanyDoesNotHaveAccessToAnyStaffingPools"
                  )}
                </Text>
              ))}
            <CustomLink
              color={Color.Destructive}
              onClick={() => setShowStaffingPoolModal(false)}
            >
              {t("General.Close")}
            </CustomLink>
          </div>
        </ModalBody>
      </Modal>
    </ProfileGrid>
  );
};
